import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { Select } from "../../../../../../../shared/Select/Select";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import {
  chargePaymentStripeCardReader,
  fetchAllClinics,
  getCardreaders,
} from "../../../../../../../Actions/Sales/salesActions";
import Loader from "../../../../../../../Components/Common/Loader";
import { uiNotification } from "../../../../../../../services/UINotificationService";

function FormSwipeCard({
  getCardreaders,
  fetchAllClinics,
  cardReadersList,
  allClinics,
  chargePaymentStripeCardReader,
  chargeStripeCardReader,
}) {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, refetchInvoice } = useInvoice();

  const [userData] = useState(JSON.parse(localStorage.getItem("userData")));
  const [cardReaderList, setCardReaderList] = useState([]);
  const [cardError, setCardError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [cardSelected, setCardSelected] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(amountToPay || "");

  useEffect(() => {
    if (userData?.account?.id) {
      fetchAllClinics();
      getCardreaders(userData.account.id);
    }
  }, [userData]);

  useEffect(() => {
    if (cardReadersList?.length && allClinics?.length) {
      setCardReaderList(getListCardReaders());
    }
  }, [cardReadersList, allClinics]);

  useEffect(() => {
    if (
      chargeStripeCardReader?.data &&
      chargeStripeCardReader?.data?.status !== 200
    ) {
      uiNotification.error(chargeStripeCardReader.data.message);
      setShowLoader(false);
      onClose();
    } else if (chargeStripeCardReader?.data?.status === 200) {
      const fetchInvoiceDetailInterval = setInterval(() => {
        // Start 3 seconds interval
        refetchInvoice().then((response) => {
          if (response?.data?.status === "paid") {
            // If invoice status changed to paid
            setShowLoader(false); // Hide loader
            uiNotification.success("Payment Successfully!"); // Show success message
            clearInterval(fetchInvoiceDetailInterval); // Stop interval
            onClose();
          }
          if (response?.data?.status === "partial paid") {
            // If invoice status is changed to partial paid
            setShowLoader(false); // Hide loader
            uiNotification.success("Partial Paid Successfully!"); // Show success message
            clearInterval(fetchInvoiceDetailInterval); // Stop interval
            onClose();
          }
        });
      }, 3000);

      setTimeout(() => {
        // After 30 sec if invoice status is still draft
        if (invoice.status === "draft") {
          clearInterval(fetchInvoiceDetailInterval); // Stop interval
          setShowLoader(false); // Hide loader
          uiNotification.error("Payment Failed!"); // Show fail message
          onClose();
        }
      }, 30000);
    }
  }, [chargeStripeCardReader]);

  useEffect(() => {
    setCardSelected(getDefaultCardReader());
  }, [cardReaderList]);

  const chargePaymentCardreaderReq = () => {
    if (checkErrors()) return;
    let formData = {
      transaction_type: "terminal_emv",
      provider: "stripe",
      pos_device: parseInt(cardSelected.id),
      invoice: parseInt(invoice.id),
      account_id: userData.account.id,
      amount: paymentAmount,
    };
    setShowLoader(true);
    chargePaymentStripeCardReader(formData);
    uiNotification.success("Payment sent to card reader!");
  };

  const cardOptions =
    cardReaderList?.length &&
    cardReaderList.map((c) => ({
      label: `${c.serialNumber} (${c.clinicName})`,
      value: c.serialNumber,
      id: c.id,
    }));

  const getListCardReaders = () => {
    const cardReaderTable = cardReadersList.map((cardReader) => {
      let getClinicById = allClinics.find(
        (clinic) => clinic.id === cardReader.clinic_id,
      );

      if (getClinicById && cardReader.payworks_status === "active") {
        return {
          serialNumber: cardReader.serial_number,
          clinicName: getClinicById.clinic_name,
          id: cardReader.id,
          isDefault: cardReader.is_default,
        };
      }
      if (cardReader.clinic_id === 0) {
        return {
          serialNumber: cardReader.serial_number,
          clinicName: userData.account.name,
          id: cardReader.id,
          isDefault: cardReader.is_default,
        };
      }
      return null;
    });

    return cardReaderTable;
  };

  const getDefaultCardReader = () => {
    const defaultReader = cardReaderList?.find((cardReader) =>
      Boolean(cardReader.isDefault),
    );
    return {
      label: `${defaultReader?.serialNumber} (${defaultReader?.clinicName})`,
      value: defaultReader?.serialNumber,
      id: defaultReader?.id,
    };
  };

  const checkErrors = () => {
    let error = false;
    if (!cardSelected?.value) {
      error = true;
      uiNotification.error("Please select a card reader!");
      setCardError(true);
    }
    if (paymentAmount < 0.5 || !paymentAmount) {
      error = true;
      uiNotification.error("Please enter minimum amount $0.5");
      setAmountError(true);
    }
    if (paymentAmount > amountToPay) {
      error = true;
      uiNotification.error("Please enter maximum amount $" + amountToPay);
      setAmountError(true);
    }
    if (!userData?.account?.id) {
      error = true;
    }
    return error;
  };

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  return (
    <div>
      <div className="col-sm-6 p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={paymentAmount}
          isError={amountError}
          onChange={(value) => {
            setPaymentAmount(value);
            setAmountError(false);
          }}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
      </div>
      <div className="col-sm-12 p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.cardReader")}
        </InputLabel>
        <Select
          size="medium"
          isError={cardError}
          value={cardReaderList?.length > 0 ? cardSelected : null}
          onChange={(option) => {
            setCardSelected(option);
            setCardError(false);
          }}
          options={cardOptions || []}
        />
      </div>

      <ModalCharge.Footer
        onClose={onClose}
        isCharging={false}
        onCharge={chargePaymentCardreaderReq}
      />

      <Loader isFullWidth={true} showLoader={showLoader} />
    </div>
  );
}

FormSwipeCard.propTypes = {
  getCardreaders: PropTypes.func,
  fetchAllClinics: PropTypes.func,
  cardReadersList: PropTypes.array,
  allClinics: PropTypes.array,
  chargePaymentStripeCardReader: PropTypes.func,
  chargeStripeCardReader: PropTypes.object,
};

const mapStateToProps = (state) => ({
  cardReadersList: state.SalesReducer.cardReadersList,
  allClinics: state.SalesReducer.allClinics,
  chargeStripeCardReader: state.SalesReducer.chargeStripeCardReader,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getCardreaders, fetchAllClinics, chargePaymentStripeCardReader },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FormSwipeCard));
