import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import StripePaymentForm from "../../../../../../../Components/Sales/FrontdeskCheckout/StripePaymentForm";
import Loader from "../../../../../../../Components/Common/Loader";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { dispatch } from "../../../../../../../store/store";
import { getStripePaymentIntent } from "../../../../../../../Actions/Stripe/StripeActions";
import { fetchInvoiceDetail } from "../../../../../../../Actions/Sales/salesActions";

import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { uiNotification } from "../../../../../../../services/UINotificationService";

function FormManual({ stripeIntent, getStripePaymentIntent }) {
  let invoiceDetailInterval = null;
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, refetchInvoice, isGuest } = useInvoice();

  const [amountError, setAmountError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(amountToPay || "");
  const [saveCard, setSaveCard] = useState(false);
  const [displayStripeForm, setDisplayStripeForm] = useState(false);

  const generateStripeForm = async () => {
    if (checkErrors()) return;

    let stripeFormData = {};
    stripeFormData.invoice_id = invoice.id;
    stripeFormData.amount = paymentAmount;

    setShowLoader(true);
    try {
      await getStripePaymentIntent(stripeFormData);
    } catch (error) {
      uiNotification.error(error.message);
    } finally {
      setShowLoader(false);
    }
    setDisplayStripeForm(true);
  };

  const checkErrors = () => {
    let error = false;
    if (paymentAmount < 0.5 || !paymentAmount) {
      error = true;
      uiNotification.error("Please enter minimum amount $0.5");
      setAmountError(true);
    }
    if (paymentAmount > amountToPay) {
      error = true;
      uiNotification.error("Please enter maximum amount $" + amountToPay);
      setAmountError(true);
    }
    return error;
  };

  const cancelStripeForm = () => {
    setDisplayStripeForm(false);
  };

  const completePayment = async () => {
    setShowLoader(true);
    await refetchInvoice();
    invoiceDetailInterval = setInterval(() => {
      refetchInvoice().then((response) => {
        if (response?.data?.status !== "pending") {
          clearInterval(invoiceDetailInterval);
          uiNotification.success("Payment done successfully");
          dispatch(checkoutInvoice.actions.paymentViewClose());
          setShowLoader(false);
        }
      });
    }, 2000);
  };

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  return (
    <div className="p-t-10 p-b-20">
      {stripeIntent?.paymentIntentId && displayStripeForm ? (
        <div className="p-t-10 p-b-20">
          <StripePaymentForm
            type={"paymentIntent"}
            onSubmit={completePayment}
            onCancel={cancelStripeForm}
            invoiceId={invoice.id}
            saveCard={saveCard}
          />

          {!isGuest && (
            <div className="col-sm-6 m-t-25">
              <Checkbox
                size="small"
                label={tSales("checkoutInvoice.saveCcToAccount")}
                isChecked={saveCard}
                onChange={() => setSaveCard(!saveCard)}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-column">
          <div className="col-sm-8 p-b-10">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={paymentAmount}
              isError={amountError}
              onChange={(value) => {
                setPaymentAmount(value);
                setAmountError(false);
              }}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
          </div>

          <ModalCharge.Footer
            onClose={onClose}
            isCharging={false}
            onCharge={generateStripeForm}
            className={"no-margin p-l-15 p-r-15"}
          />
        </div>
      )}
      <Loader isFullWidth={true} showLoader={showLoader} />
    </div>
  );
}

FormManual.propTypes = {
  stripeIntent: PropTypes.object,
  getStripePaymentIntent: PropTypes.func,
};

const mapStateToProps = (state) => ({
  stripeIntent: state.StripeReducer.stripeIntent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getStripePaymentIntent, fetchInvoiceDetail }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormManual);
