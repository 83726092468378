import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";
import { uiNotification } from "../../services/UINotificationService.js";

const languageData = JSON.parse(localStorage.getItem("languageData"));

positionFooterCorrectly();

export function exportSalesSummary(formData, reportType, newReq) {
  return async (dispatch) => {
    const url = !newReq
      ? "sales/export"
      : !reportType
      ? "sales/export-transactions"
      : "sales/export-invoices";

    const request = !newReq
      ? http.post(url, formData)
      : http.get(url, formData ? { params: formData } : {});

    return request
      .then((response) => {
        dispatch({ type: "EXPORT_FILE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        const errorMsg = error.response?.data;
        dispatch({ type: "EXPORT_FILE", payload: errorMsg });
        return Promise.reject(errorMsg);
      });
  };
}

export function fetchInvoices(formData) {
  return async (dispatch) => {
    return http
      .post("sales/invoices", formData)
      .then((response) => {
        dispatch({ type: "INVOICES_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "INVOICES_LIST", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function createEditInvoiceText(formData) {
  return (dispatch) => {
    http
      .post("sales/invoice_text", formData)
      .then((response) => {
        dispatch({ type: "CREATE_EDIT_INVOICES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_EDIT_INVOICES",
          payload: error.response.data,
        });
      });
  };
}

export function fetchInvoiceText(formData) {
  return (dispatch) => {
    http
      .post("sales/invoice_text", formData)
      .then((response) => {
        dispatch({ type: "FETCH_INVOICES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_INVOICES", payload: error.response.data });
      });
  };
}

export function getCashDrawer() {
  return (dispatch) => {
    http
      .post("cash-drawer")
      .then((response) => {
        dispatch({ type: "GET_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_CASH_DRAWER", payload: error.response.data });
      });
  };
}

export function changeDrawerLocation(clinicID) {
  return (dispatch) => {
    http
      .post("change-clinic-location/" + clinicID)
      .then((response) => {
        dispatch({ type: "CHANGE_DRAWER_LOCATION", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_DRAWER_LOCATION",
          payload: error.response.data,
        });
      });
  };
}

export function viewOpenedDrawer(clinicID) {
  return (dispatch) => {
    http
      .get("show-opened-drawer/" + clinicID)
      .then((response) => {
        dispatch({ type: "VIEW_OPENED_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_OPENED_DRAWER", payload: error.response.data });
      });
  };
}

export function updateCashDrawer(clinicID) {
  return (dispatch) => {
    http
      .post("update-cash-drawer/" + clinicID)
      .then((response) => {
        dispatch({ type: "TAKE_DRAWER_CONTROL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "TAKE_DRAWER_CONTROL", payload: error.response.data });
      });
  };
}

export function getOpenCashDrawer(clinicID) {
  return (dispatch) => {
    http
      .get("open-cash-drawer/" + clinicID)
      .then((response) => {
        dispatch({ type: "GET_OPEN_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_OPEN_CASH_DRAWER",
          payload: error.response.data,
        });
      });
  };
}

export function openCashDrawer(formData) {
  return (dispatch) => {
    http
      .post("open-cash-drawer", formData)
      .then((response) => {
        dispatch({ type: "POST_OPEN_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POST_OPEN_CASH_DRAWER",
          payload: error.response.data,
        });
      });
  };
}

export function closeCashDrawer(formData) {
  return (dispatch) => {
    http
      .post("close-cash-drawer", formData)
      .then((response) => {
        dispatch({ type: "CLOSE_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLOSE_CASH_DRAWER", payload: error.response.data });
      });
  };
}

export function cashInDrawer(formData) {
  return (dispatch) => {
    http
      .post("cash-in-drawer", formData)
      .then((response) => {
        dispatch({ type: "CASH_IN_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CASH_IN_DRAWER", payload: error.response.data });
      });
  };
}

export function cashOutDrawer(formData) {
  return (dispatch) => {
    http
      .post("cash-out-drawer", formData)
      .then((response) => {
        dispatch({ type: "CASH_OUT_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CASH_OUT_DRAWER", payload: error.response.data });
      });
  };
}

export function bankDropDrawer(formData) {
  return (dispatch) => {
    http
      .post("bank-drop", formData)
      .then((response) => {
        dispatch({ type: "BANK_DROP_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "BANK_DROP_DRAWER", payload: error.response.data });
      });
  };
}

export function cashRegisterLogs(formData) {
  return (dispatch) => {
    http
      .get("cash-register-logs", formData)
      .then((response) => {
        dispatch({ type: "CASH_REGISTER_LOGS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CASH_REGISTER_LOGS", payload: error.response.data });
      });
  };
}

export function cashDrawerHistory(formData) {
  return (dispatch) => {
    http
      .get("drawer-filter", formData)
      .then((response) => {
        dispatch({ type: "GET_CASH_DRAWER_HISTORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CASH_DRAWER_HISTORY",
          payload: error.response.data,
        });
      });
  };
}

export function editClosedDrawer(formData) {
  return (dispatch) => {
    http
      .post("drawer-close-edit", formData)
      .then((response) => {
        dispatch({ type: "EDIT_CLOSED_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EDIT_CLOSED_DRAWER", payload: error.response.data });
      });
  };
}

export function addEditPopupReceipt(formData) {
  return (dispatch) => {
    http
      .post("add-receipt", formData)
      .then((response) => {
        dispatch({ type: "ADD_EDIT_POPUP_RECEIPT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_EDIT_POPUP_RECEIPT",
          payload: error.response.data,
        });
      });
  };
}

export function exportCashRegisterLogs(formData) {
  return (dispatch) => {
    http
      .get("export-cash-register-logs", formData)
      .then((response) => {
        dispatch({ type: "EXPORT_CASH_DRAWER_LOG", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "EXPORT_CASH_DRAWER_LOG",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export const fetchFrontdeskInvoices = (formData) => {
  return async (dispatch) => {
    return http
      .post("get-frontdesk-invoices", formData)
      .then((response) => {
        dispatch({ type: "FRONTDESK_INVOICES_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "FRONTDESK_INVOICES_LIST",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const fetchInvoiceDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: "FRONTDESK_INVOICE_DATA_LOADING" });
    http
      .get("get-invoice-detail/" + id)
      .then((response) => {
        dispatch({ type: "FRONTDESK_INVOICE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "FRONTDESK_INVOICE_DATA",
          payload: error.response ? error.response.data : "",
        });
        uiNotification.error(languageData.global[error.response.data.message]);
      });
  };
};

export function getCardreaders(accountId) {
  return (dispatch) => {
    http
      .get(`payment/card_readers/${accountId}`)
      .then((response) => {
        dispatch({
          type: "GET_CARDREADERS_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({ type: "GET_CARDREADERS_ERROR", payload: e.response.error });
      });
  };
}

export const fetchAllClinics = () => {
  return (dispatch) => {
    http
      .get("clinics")
      .then((response) => {
        dispatch({ type: "ALL_CLINIC_LIST", payload: response.data.data });
      })
      .catch((e) => {
        dispatch({ type: "ALL_CLINIC_LIST", payload: e.response.data });
      });
  };
};

export const chargePaymentStripeCardReader = (formdata) => {
  return (dispatch) => {
    dispatch({ type: "CHARGE_STRIPE_CARDREADER_LOADING" });
    http
      .post("payment/checkout", formdata)
      .then((response) => {
        dispatch({
          type: "CHARGE_STRIPE_CARDREADER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "CHARGE_STRIPE_CARDREADER_ERROR",
          payload: e.response.data,
        });
      });
  };
};

export const mergeAndCheckoutInvoice = (formData) => {
  return (dispatch) => {
    dispatch({ type: "MERGE_AND_CHECKOUT_INVOICE_LOADING" });
    http
      .post("pos-invoice-merge/merge", formData)
      .then((response) => {
        dispatch({
          type: "MERGE_AND_CHECKOUT_INVOICE_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "MERGE_AND_CHECKOUT_INVOICE_ERROR",
          payload: e.response.data,
        });
      });
  };
};
