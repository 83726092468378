import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { connect } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import calenLogo from "../../../_legacy/images/calender.svg";
import { showFormattedDate } from "../../../Utils/services.js";
import { WidgetLoader } from "../components/WidgetLoader.js";
import { fetchMostUsedDiscounts } from "../../../Actions/Dashboard/dashboardActions.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const options = (type, discountData) => {
  let data = [];
  if (type == "second") {
    if (discountData && discountData.length > 0) {
      discountData.map((obj) => {
        let percent = parseFloat(obj.users_percentage);
        let tempObj = {};
        tempObj.title = obj.discount_type;
        tempObj.name = obj.discount_type + "  " + percent + "%";
        tempObj.y = percent;
        tempObj.z = percent;
        tempObj.totalNum = obj.discounted_users;
        data.push(tempObj);
      });
    }
  }

  return {
    chart: {
      type: "pie",
      margin: [-10, 250, 50, 15],
      height: 500,
      width: 500,
      backgroundColor: "rgba(255, 255, 255, 0.0)",
    },
    title: {
      text: "",
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "<b>{point.name}</b><br/>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      maxHeight: 165,
      symbolHeight: 14,
      symbolWidth: 14,
      symbolRadius: 3,
      width: 320,
      itemMarginTop: 4,
      itemMarginBottom: 2,
      x: 100,
      y: 150,
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "50%",
        zMin: 0,
        data: data,
      },
    ],
  };
};

class WidgetMostUsedDiscounts extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const mudDateRange = JSON.parse(localStorage.getItem("mudDateRange"));
    const valR = mudDateRange ? mudDateRange : "";
    const startDate = valR.startDate;
    const endDate = valR.endDate;
    let date = new Date();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    date =
      date.getFullYear() +
      "-" +
      month +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    this.state = {
      globalLang: languageData.global,
      discountData: [],
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      lastPage: 1,
      showLoader9: false,

      dateRangePicker: {
        selection: {
          startDate: startDate ? startDate : date,
          endDate: endDate ? endDate : date,
          key: "selection",
        },
      },
      to_date: date,
      from_date: date,
      showCalendar: false,

      clicked: 0,
    };

    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
    const dateData = JSON.parse(localStorage.getItem("mudDateRange"));
    let formData = {
      params: {
        from_date: dateData ? dateData.startDate : this.state.from_date,
        to_date: dateData ? dateData.endDate : this.state.to_date,
      },
    };
    this.setState({
      showLoader9: true,
      to_date: dateData ? dateData.endDate : this.state.to_date,
      from_date: dateData ? dateData.startDate : this.state.from_date,
    });

    this.props.fetchMostUsedDiscounts(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.discountData != undefined &&
      nextProps.discountData.data != prevState.discountData.data &&
      nextProps.discountData.status == 200 &&
      prevState.discountTime != nextProps.discountTime
    ) {
      returnState.discountData = nextProps.discountData.data;
      returnState.discountTime = nextProps.discountTime;

      returnState.showLoader9 = false;
    }

    return returnState;
  }

  handleInputChange = () => {
    let returnState = {};
    returnState.dataChanged = true;
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.nodeMostUsedDiscount) {
      if (
        this.nodeMostUsedDiscount.contains(e.target) &&
        this.state.showCalendar === true
      ) {
        return;
      } else if (
        this.nodeMostUsedDiscount.contains(e.target) &&
        this.state.showCalendar === false
      ) {
        this.setState({ showCalendar: true });
      } else if (
        !this.nodeMostUsedDiscount.contains(e.target) &&
        this.state.showCalendar === true
      ) {
        this.setState({ showCalendar: false });
      }
    }
  };

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendar = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendar = true;
    } else {
      showCalendar = false;
    }

    this.setState({ showCalendar: showCalendar });
  };

  handleSubmit = (event, value) => {
    if (typeof event === "object") {
      event.preventDefault();
    }
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
        from_date:
          value != undefined && value.from_date != undefined
            ? value.from_date
            : this.state.from_date,
        to_date:
          value != undefined && value.to_date != undefined
            ? value.to_date
            : this.state.to_date,
      },
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      providerSalesList: [],
      showLoader9: true,
      innerbox_select_all: false,
    });

    this.props.fetchMostUsedDiscounts(formData);
  };

  handleRangeChange = (which, payload) => {
    let save_start_date = "";
    let save_end_date = "";
    if (payload) {
      let date_1 = new Date(payload.selection.startDate);
      let date_2 = new Date(payload.selection.endDate);
      let month_1 = date_1.getMonth() + 1;
      let month_2 = date_2.getMonth() + 1;
      let day_1 = date_1.getDate();
      let day_2 = date_2.getDate();
      if (month_1 < 10) {
        month_1 = "0" + month_1;
      }
      if (month_2 < 10) {
        month_2 = "0" + month_2;
      }
      day_1 = day_1 < 10 ? "0" + day_1 : day_1;
      day_2 = day_2 < 10 ? "0" + day_2 : day_2;
      save_start_date = date_1.getFullYear() + "-" + month_1 + "-" + day_1;
      save_end_date = date_2.getFullYear() + "-" + month_2 + "-" + day_2;
      let payloadValue = {
        startDate: save_start_date,
        endDate: save_end_date,
        key: "selection",
      };
      localStorage.setItem("mudDateRange", JSON.stringify(payloadValue));
    }
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    startDate = save_start_date;
    endDate = save_end_date;

    let clicked = this.state.clicked + 1;

    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      showCalendar: showCalendar,
      from_date: startDate,
      to_date: endDate,
      clicked: clicked,
    });

    if (clicked && clicked % 2 === 0) {
      this.handleSubmit(which, { from_date: startDate, to_date: endDate });
    }
  };

  render() {
    return (
      <div className="setting-setion m-b-15">
        <div className="dashboardSectionTitle zIndex1">
          <b>Most Used Discounts</b>
          <div className="memberRightActions">
            <div
              className="search-bg new-calender pull-left"
              ref={(nodeMostUsedDiscount) => {
                this.nodeMostUsedDiscount = nodeMostUsedDiscount;
              }}
            >
              <img src={calenLogo} alt="" />
              {this.state.showCalendar && (
                <DateRangePicker
                  ranges={[
                    {
                      ...this.state.dateRangePicker.selection,
                      startDate: new Date(
                        `${this.state.dateRangePicker.selection.startDate} 00:00:00`,
                      ),
                      endDate: new Date(
                        `${this.state.dateRangePicker.selection.endDate} 00:00:00`,
                      ),
                    },
                  ]}
                  onChange={this.handleRangeChange.bind(
                    this,
                    "dateRangePicker",
                  )}
                  className={"CalendarPreviewArea"}
                  maxDate={new Date()}
                  dragSelectionEnabled={false}
                  ref={(refMostUsedDiscount) => {
                    this.refMostUsedDiscount = refMostUsedDiscount;
                  }}
                />
              )}
              <input
                type="text"
                className="input-cal setting-search-input p-b-5"
                name="calendar-input"
                value={
                  this.state.from_date
                    ? showFormattedDate(this.state.from_date, false) +
                      `-` +
                      showFormattedDate(this.state.to_date, false)
                    : ""
                }
                autoComplete="off"
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
        {this.state.discountData && (
          <div
            className="dash-box-content  businessGraphOuter newAppintmentGraph"
            id="mostUsedDiscount"
            data-highcharts-chart="0"
          >
            <div
              className={
                this.state.discountData.length !== 0
                  ? "highcharts-container"
                  : "no-display"
              }
              data-highcharts-chart="0"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "400px",
                height: "200px",
                textAlign: "left",
                lineHeight: "normal",
                zIndex: "0",
                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
              }}
            >
              <PieChart
                highcharts={Highcharts}
                options={options("second", this.state.discountData)}
              />
            </div>
            {
              <div
                className={
                  this.state.discountData.length !== 0
                    ? "no-record no-display"
                    : "no-record"
                }
              >
                {this.state.globalLang.sorry_no_record_found}
              </div>
            }
          </div>
        )}
        {this.state.showLoader9 && <WidgetLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  const returnState = {};
  if (state.DashboardReducer.action === "MOST_USED_DISCOUNTS_DATA") {
    if (state.DashboardReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.DashboardReducer.data.message],
      );
    } else {
      returnState.discountData = state.DashboardReducer.data;
      returnState.discountTime = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMostUsedDiscounts: fetchMostUsedDiscounts,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetMostUsedDiscounts);
