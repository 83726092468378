import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getInvoiceData,updateInvoice,takePayment,saveWhileWorking,searchProductForInvoice } from "../../../Actions/Clients/clientsAction";
import {
  getCurrencySymbol,
  getIsPosEnabled,
  isPositiveNumber,
  numberFormat,
  roundOffNum
} from '../../../Utils/services';
import Loader from '../../Common/Loader'
import {getProcedureData} from "../../../Actions/Procedures/procedureActions";
import { CircularProgress } from '../../../shared/CircularProgress/CircularProgress';
import { Checkbox } from '../../../shared/Checkbox/Checkbox';
import { isShopifyAvailable } from '../../../helpers/featureFlags';
import { withShopifyEnabled } from './hocs/withShopifyEnabled';
import { shortenClinicName } from "../../../helpers/general";
import { uiNotification } from '../../../services/UINotificationService';

class HealthInvoiceCreation extends Component{
constructor(props){
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
        shopify_dont_deduct_stock: 0,
        invoiceData : [],
        procedureID : this.props.match.params.procedureID,
        method : this.props.match.params.type,
        subTotal : 0,
        discount : 0,
        total : 0,
        discountType : 'dollar',
        showLoader : false,
        subTotal_discount : 0,
        showDeleteModal : false,
        settingsLang : languageData.settings,
        total_tax : 0,
        showSaveButton : false,
        productList : [],
        searched_product_id: 0,
        searched_product_name: '',
        is_product_search: false,
        search_product_keyword: '',
        globalLang : languageData.global,
        serviceList : [],
        default_user_id : JSON.parse(localStorage.getItem('userData')).user.id,
    }
}

componentDidMount(){
    if(this.state.procedureID && this.state.method == 'create'){
        this.setState({ showLoader : true })
        this.props.getInvoiceData(this.state.procedureID);
    }
}

componentDidUpdate(prevProps, prevState) {
    if (this.state.patientData?.id !== prevState.patientData?.id && this.state.procedureID) {
        this.props.getProcedureData(this.state.procedureID);
        this.setState({ showLoader: true })
    }
}

static getDerivedStateFromProps(nextProps,prevState){
    let returnState = {};
    if(nextProps.invoiceData != undefined && nextProps.invoiceData != prevState.invoiceData && nextProps.invoiceDataTime != prevState.invoiceDataTime){
        let invoiceList = [];
        let subTotal = 0;
        let total = 0;
        let address = [];
        if(nextProps.invoiceData.invoice_status == 'paid'){
          nextProps.history.push(`/clients/invoice/${nextProps.invoiceData.invoice_id}/${nextProps.invoiceData.patient.id}/profile`);
        }
        // returnState.serviceList = nextProps.invoiceData.services;
        // returnState.productList = nextProps.invoiceData.products;
        returnState.patientData = nextProps.invoiceData.patient;
        returnState.invoice_number = nextProps.invoiceData.invoice_number;
        returnState.invoice_id = nextProps.invoiceData.invoice_id;
        returnState.clinic = nextProps.invoiceData.clinic;
        returnState.invoice_status = nextProps.invoiceData.invoice_status == '' ? 'draft' : nextProps.invoiceData.invoice_status;
        
        if ( nextProps.invoiceData.patient !== undefined && nextProps.invoiceData.patient.address_line_1 ) {
            address.push(nextProps.invoiceData.patient.address_line_1)
          }
      
          if ( nextProps.invoiceData.patient !== undefined && nextProps.invoiceData.patient.address_line_2 ) {
            address.push(nextProps.invoiceData.patient.address_line_2)
          }
      
          if ( nextProps.invoiceData.patient !== undefined && nextProps.invoiceData.patient.city ) {
            address.push(nextProps.invoiceData.patient.city)
          }
      
          if ( nextProps.invoiceData.patient !== undefined && nextProps.invoiceData.patient.state ) {
            address.push(nextProps.invoiceData.patient.state)
          }
      
          if ( nextProps.invoiceData.patient !== undefined && nextProps.invoiceData.patient.pincode ) {
            address.push(nextProps.invoiceData.patient.pincode)
          }
      
          if ( nextProps.invoiceData.patient !== undefined && nextProps.invoiceData.patient.country ) {
            address.push(nextProps.invoiceData.patient.country)
          }
          returnState.patientAddress = address;
        if(nextProps.invoiceData.invoice_details.length == 0){
            invoiceList.push({
                index : 0,
                id: '',
                name :'',
                quantity: '',
                rate: '',
                amount: '',
                discount: '',
                tax: '',
                type : "product",
                added_via_api : false,
                deleted : false
            })
            returnState['type-0'] = 'product';
            returnState['product-0'] = '';
            returnState['service-0'] = '';
            returnState['quantity-0'] = 1;
            returnState['rate-0'] = 0;
            returnState['amount-0'] = 0;
            returnState['discount-0'] = 0;
            returnState['tax-0'] = 0;
            returnState['tax-value-0'] = 0;
            returnState['type-error-0'] = false;
            returnState['product-error-0'] = false;
            returnState['service-error-0'] = false;
            returnState['quantity-error-0'] = false;
            returnState['discount-error-0'] = false;
            returnState['tax-error-0'] = false;
            returnState['amount-error-0'] = false;
            returnState['userid-0'] = prevState.default_user_id;
            returnState.total = 0;
            returnState.subTotal = 0;
            returnState.showLoader = false;
           
        } else {
            let total_tax = 0;
            let total_discount = 0;
            let subTotal = 0;
            let custom_discount = 0;
            let internal_subtotal = 0;
            let discount_value = nextProps.invoiceData.discount_value > 0 ? nextProps.invoiceData.discount_value.toFixed(2) : 0;
            nextProps.invoiceData.invoice_details.map((data,index) => {
              internal_subtotal = internal_subtotal + (data.bill_for_whole_unit == 1 ? (Math.ceil(data.quantity * data.rate)) :(data.quantity * data.rate)) ;
            });
            internal_subtotal = internal_subtotal.toFixed(2);
            nextProps.invoiceData.invoice_details.map((data,index) => {
                total_discount = total_discount + data.discount;
                subTotal = subTotal + (data.bill_for_whole_unit == 1 ? (Math.ceil(data.quantity * data.rate)) :(data.quantity * data.rate)) ;
                invoiceList.push({
                    index : invoiceList.length,
                    id: data.id,
                    name :data.name,
                    quantity: data.quantity,
                    rate: data.rate,
                    amount: data.amount,
                    discount: data.discount,
                    tax: data.tax,
                    type : data.type,
                    added_via_api : true,
                    deleted : false
                })
            returnState['type-' + index] = data.type;
            returnState['product-' + index] = data.id;
            returnState['service-' + index] = data.id;
            returnState['quantity-' + index] = data.quantity;
            returnState['rate-' + index] = data.rate;
            returnState['userid-' + index] = data.user_id;
            let set_amount = 0;
            let set_tax = 0;
            let tax_percent = 0;
            let quantity = data.bill_for_whole_unit == 1 ? Math.ceil(data.quantity) : data.quantity;
            let length =  nextProps.invoiceData.invoice_details.length;
            if(data.type == 'product'){
              set_amount = (quantity * data.rate)
              let discount_part = discount_value > 0 ? ((discount_value * set_amount)/internal_subtotal).toFixed(2) : 0;
              set_amount = set_amount - data.discount;
              set_amount = discount_part > 0 ? set_amount - discount_part : set_amount;
              set_tax = (set_amount * data.tax_rate)/100
              // set_amount = set_amount + set_tax;
            } else {
              set_amount = (quantity * data.rate);
              let discount_part = discount_value > 0 ? ((discount_value * set_amount)/internal_subtotal).toFixed(2) : 0;
              set_amount = set_amount - data.discount;
              set_amount = discount_part > 0 ? set_amount - discount_part : set_amount;
              tax_percent = data.tax > 0 ? (data.tax/set_amount) * 100 : 0;
              // set_amount = set_amount + data.tax;
            }
            
            returnState['amount-' + index] = set_amount > 0 ? set_amount.toFixed(2) : 0;
            returnState['discount-' + index] = data.discount > 0 ? data.discount.toFixed(2) : 0;
          
            returnState['tax-' + index] = data.type == 'service' ? Math.round(tax_percent) : data.tax_rate;
            returnState['tax-value-' + index] = data.type == 'service' ? data.tax : set_tax;
            total_tax = total_tax + returnState['tax-value-' + index];
            returnState['type-error-' + index] = false;
            returnState['product-error-' + index] = false;
            returnState['service-error-' + index] = false;
            returnState['quantity-error-' + index] = false;
            returnState['discount-error-' + index] = false;
            returnState['tax-error-' + index] = false;
            returnState['search_product_keyword' + index] = data.name;
            returnState['search_service_keyword' + index] = data.name;
            returnState['bill-for-whole-unit-' + index] = data.bill_for_whole_unit;
            returnState['amount-error-' + index] = false;
            
            })
            returnState.total_tax = total_tax;
            returnState.discountType = nextProps.invoiceData.discount_type;
            returnState.subTotal = subTotal;
            // if(nextProps.invoiceData.discount_type == 'dollar'){
            //   custom_discount = nextProps.invoiceData.discount_value;
            // } else if(nextProps.invoiceData.discount_type == 'percentage') {
            //   let subtotal = subTotal - total_discount;
            //   let discount_percent = Number(nextProps.invoiceData.discount_percentage);
            //   custom_discount = (discount_percent * subtotal)/100
            // }
            returnState.subTotal_discount = nextProps.invoiceData.discount_value + total_discount;
            returnState.discount = nextProps.invoiceData.discount_type == 'percentage' ? nextProps.invoiceData.discount_percentage : nextProps.invoiceData.discount_value;
            returnState.total = subTotal - (nextProps.invoiceData.discount_value + total_discount) + total_tax;
            returnState.discount_number = nextProps.invoiceData.discount_value;

        // returnState.total = nextProps.invoiceData.total;
       
        // returnState.subTotal_discount = nextProps.invoiceData.subTotal_discount;
        
        // returnState.discount = nextProps.invoiceData.discount_value;
        
        }
        
        returnState.showLoader = false;
        returnState.invoiceDataTime = nextProps.invoiceDataTime;
        returnState.invoiceData = invoiceList;
        
    }


    if(nextProps.redirect != undefined && nextProps.redirect == true && nextProps.takePaymentDataTime != prevState.takePaymentDataTime){
        returnState.takePaymentDataTime = nextProps.takePaymentDataTime;
        returnState.showLoader = false;
        // nextProps.history.push(`/clients/profile/${prevState.patientData.id}`)
        nextProps.history.push(`/sales/checkout-invoice/${nextProps.invoice}/clients`);
    }

    if(nextProps.redirectToClients != undefined && nextProps.redirectToClients == true && nextProps.saveHealthInvoiceDataTime != prevState.saveHealthInvoiceDataTime){
        returnState.saveHealthInvoiceDataTime = nextProps.saveHealthInvoiceDataTime;
        returnState.showLoader = false;
        nextProps.history.push(`/clients/profile/${prevState.patientData.id}`)
    }

    if(nextProps.savingWhileWorking != undefined && nextProps.savingWhileWorking == true && nextProps.savingWhileWorkingTime != prevState.savingWhileWorkingTime){
        returnState.savingWhileWorkingTime = nextProps.savingWhileWorkingTime;
        returnState.showSaveButton = false;
        // nextProps.getInvoiceData(prevState.procedureID);
    }

    if(nextProps.productsData != undefined && nextProps.productsData != prevState.productsData && nextProps.productsDataTime != prevState.productsDataTime){
        returnState.productsDataTime = nextProps.productsDataTime;
        returnState.productList = nextProps.productsData.products;
        returnState['is_search_first_time' + prevState.searchIndex] = nextProps.productsData.products.length == 0 ? false : true;
    }

    if(nextProps.serviceData != undefined && nextProps.serviceData != prevState.serviceData && nextProps.serviceDataTime != prevState.serviceDataTime){
        returnState.serviceList = nextProps.serviceData.services;
        returnState.serviceDataTime = nextProps.serviceDataTime;
        returnState['is_search_first_time_service' + prevState.searchIndex] = nextProps.serviceData.services.length == 0 ? false : true;
    }
    return returnState;
}

handleInputChange = (event) => {
    let returnState = {};
    returnState[event.target.name] = event.target.value;
    if(event.target.name.startsWith('type-')){
        let index = event.target.name.split('-')[1];
        returnState['quantity-' + index] = 1;
        returnState['rate-' + index] = 0;
        returnState['amount-' + index] = 0;
        returnState['discount-' + index] = 0;
        returnState['tax-' + index] = 0;
        if(event.target.value == 'product'){
            returnState['product-' + index] = '';
            returnState['search_product_keyword' + index] = '';
            returnState['is_search_first_time' + index] = false;
        } else {
            returnState['service-' + index] = '';
            returnState['search_service_keyword' + index] = '';
            returnState['is_search_first_time_service' + index] = false;
        }
    }


    if(event.target.name.startsWith('quantity-')){
        let index = event.target.name.split('-')[1];
        let quantity = 0;
        if(this.state['type-' + index] == 'service' && (event.target.value % 1 !== 0)){
          uiNotification.clear();
          uiNotification.error('Please enter an integer value for service.');
        }
        if(this.state['type-' + index] == 'product' && !isPositiveNumber(event.target.value, null, null, 4)){
          returnState['quantity-error-' + index] = true;
          this.setState(returnState);
        } else {
          returnState['quantity-error-' + index] = false;
        }
          this.internalCalculations(this.state['type-' + index],event.target.value, this.state['rate-' + index], this.state['tax-' + index], this.state['discount-' + index], this.state['bill-for-whole-unit-' + index],index)
        }


    if(event.target.name.startsWith('discount-')){
        let index = event.target.name.split('-')[1];
        let total_discount = 0;
        let subTotal = 0;
        let subTotal_for_discount = 0;
        let discount = 0;
        let returnState = {};
        this.state.invoiceData && this.state.invoiceData.map((data,i) => {
          subTotal = subTotal + (this.state['bill-for-whole-unit-' + i] == 1 ? (Math.ceil(this.state['quantity-' + i]) * this.state['rate-' + i]) : (this.state['quantity-' + i] * this.state['rate-' + i] ))
          if(i != index){
            total_discount = total_discount + Number(this.state['discount-' + i]);
          }
        });
        total_discount = total_discount + Number(event.target.value);
        subTotal_for_discount = subTotal - total_discount;
        if(this.state.discountType == 'dollar'){
          discount = this.state.discount > 0 ? this.state.discount : 0;
        } else if(this.state.discountType == 'percentage'){
          discount = (this.state.discount * subTotal_for_discount) /100;
        }
        returnState.discount_number = discount;
        returnState['discount-' + index] = event.target.value
        this.setState(returnState,() => {
          this.fullCalculationForInternal(this.state.discount_number);
        }); 
    }

    if(event.target.name.startsWith('tax-')){
        let index = event.target.name.split('-')[1];
        this.internalCalculations(this.state['type-' + index],this.state['quantity-' + index], this.state['rate-' + index], event.target.value, this.state['discount-' + index], this.state['bill-for-whole-unit-' + index],index);
    }

    if(event.target.name.startsWith('service-')){
        let index = event.target.name.split('-')[1];
        let check = this.state.serviceList.find(y => y.id == event.target.value);
        let price = check ? check.price : 0;
        returnState['rate-' + index] = price;
        this.internalCalculations('service',this.state['quantity-' + index], price, this.state['tax-' + index], this.state['discount-' + index] , 0,index)

    }

    if(event.target.name.startsWith('product-')){
        let amount = 0;
        let tax = 0;
        let index = event.target.name.split('-')[1];
        let check = this.state.productList.find(y => y.id == event.target.value);
        let price = check ? check.price_per_unit : 0;
        returnState['rate-' + index] = price;
        this.internalCalculations('product',this.state['quantity-' + index], price, this.state['tax-' + index], this.state['discount-' + index], this.state['bill-for-whole-unit-' + index],index)
    }

    if(event.target.name == 'discount'){
        if(!isPositiveNumber(event.target.value)){
            returnState.discount_error = true;
            this.setState(returnState);
        } else {
            returnState.discount_error = false;
            let subTotal_for_discount = 0;
            let subTotal = 0;
            let total_discount = 0;
            let discount = 0;
            this.state.invoiceData.map((data,index) => {
              if(data.deleted == false){
              subTotal = subTotal + (this.state['bill-for-whole-unit-' + index] == 1 ? (Math.ceil(this.state['quantity-' + index]) * this.state['rate-' + index]) : ((this.state['quantity-' + index]) * this.state['rate-' + index]));
              total_discount = total_discount + Number(this.state['discount-' + index]);
              }
          })
          subTotal_for_discount =  subTotal - total_discount;
          if(this.state.discountType == 'dollar'){
              discount = event.target.value;
          } else if(this.state.discountType == 'percentage'){
              discount = (Number(event.target.value)/100) * subTotal_for_discount;
          }
          this.fullCalculationForInternal(discount);
            this.setState(returnState);
        }
        
    }





    this.setState(returnState, () => {
        this.calculateTotal();
    });
    
    
  }

  handleDiscountChange = (event) => {
    let returnState = {};
    returnState[event.target.name] = event.target.value;
    if(event.target.name = 'discountType'){
      let discount = 0;
      let subTotal_for_discount = 0;
      let subTotal = 0;
      let total_discount = 0;
      this.state.invoiceData.map((data,index) => {
       if(data.deleted == false){
       subTotal = subTotal + (this.state['bill-for-whole-unit-' + index] == 1 ? (Math.ceil(this.state['quantity-' + index]) * this.state['rate-' + index]) : ((this.state['quantity-' + index]) * this.state['rate-' + index]));
       total_discount = total_discount + Number(this.state['discount-' + index]);
       }
   })
   subTotal_for_discount = subTotal - total_discount;
       if(event.target.value == 'dollar'){
         discount = this.state.discount;
     } else if(event.target.value == 'percentage'){
         discount = (this.state.discount/100) * subTotal_for_discount;
     }
     returnState.discount_number = discount;
     this.setState(returnState,() => {
      this.fullCalculationForInternal(discount);
     });
    
     }
  }

  internalCalculations = (type,quantity, rate, tax, discount, bill_for_whole_unit,index) => {
    let amount = 0;
    let tax_value = 0;
    let length = 0;
    let returnState = {};
    let quantity_check = quantity > 0 ? quantity : 0;
    
    if(type == 'product' && bill_for_whole_unit == 1){
      amount = Math.ceil(quantity_check) * rate;
    } else {
      amount = quantity_check * rate;
    }
    let discount_part = this.state.discount_number > 0 ? ((this.state.discount_number * amount)/this.state.subTotal) : 0 
    amount = discount > 0 ? amount - discount : amount;
    amount = discount_part > 0 ? amount - discount_part : amount;
    tax_value = tax > 0 ? (tax/100) * amount : 0;
    // amount = amount + tax_value;
    returnState['amount-' + index] = amount > 0  ? amount.toFixed(2) : 0;
    returnState['tax-value-' + index] = tax_value;
    this.setState(returnState, () => {
      this.calculateTotal();
    });
  }

  fullCalculationForInternal = (discount_number) => {
    let length = 0;
    let returnState = {};
    // this.state.invoiceData && this.state.invoiceData.map((data,index) => {
    //   if(data.deleted == false){
    //     length = length + 1;
    //   }
    // })

      
      this.state.invoiceData.map((data,index) =>{
        if(data.deleted == false){
        let amount = 0;
        let tax = 0;
        let quantity = this.state['quantity-' + index] > 0 ? this.state['quantity-' + index] : 0;
        if(this.state['type-' + index] == 'product' && this.state['bill-for-whole-unit-' + index] == 1){
          amount = Math.ceil(quantity) * this.state['rate-' + index];
        } else {
          amount = quantity * this.state['rate-' + index];
        }
        let divided_value = discount_number > 0 ? ((discount_number * amount)/this.state.subTotal) : 0;
        amount = this.state['discount-' + index] > 0 ? amount - this.state['discount-' + index] : amount;
        amount = divided_value > 0 ? amount - divided_value : amount;
        tax    = this.state['tax-' + index] > 0 ? (this.state['tax-' + index]/100) * amount : 0;
        amount = amount + tax;
        returnState['amount-' + index] = amount > 0 ? amount.toFixed(2) : 0;
        returnState['tax-value-' + index] = tax > 0 ? tax.toFixed(5) : 0;
      }
      })
      this.setState(returnState, () => {
        this.calculateTotal();
      })
  }

  calculateTotal = () => {
    let subTotal = 0;
    let total = 0;
    let discount = 0;
    let returnState = {};
    let total_tax =  0;
    let total_discount = 0;
    let subTotal_for_discount = 0;
    let check = [];
    this.state.invoiceData.map((data,index) => {
        if(data.deleted == false){
        subTotal = subTotal + (this.state['bill-for-whole-unit-' + index] == 1 ? (Math.ceil(this.state['quantity-' + index]) * this.state['rate-' + index]) : ((this.state['quantity-' + index]) * this.state['rate-' + index]));
        total_tax = total_tax + Number(this.state['tax-value-' + index]);
        total_discount = total_discount + Number(this.state['discount-' + index]);
        }
    })
    subTotal_for_discount =  subTotal - total_discount;
    if(this.state.discountType == 'dollar'){
        discount = this.state.discount;
    } else if(this.state.discountType == 'percentage'){
        discount = (this.state.discount/100) * subTotal_for_discount;
    }
    returnState.discount_number = discount;
    discount = Number(discount) + Number(total_discount);
    total = Number(subTotal) - discount + total_tax;
    returnState.total = total > 0 ? total : 0;
    returnState.subTotal_discount = discount;
    returnState.subTotal = subTotal > 0 ? subTotal : 0;
    returnState.total_tax = total_tax;
    

    this.setState(returnState, () => { 
        this.handleSaveSubmit(3)
    });
  }

  addNewLine = () => {
      let invoiceData = [];
      let returnState = {};
      invoiceData.push({
        index : this.state.invoiceData.length,
        id: '',
        name :'',
        quantity: '',
        rate: 0,
        amount: 0,
        discount: 0,
        tax: 0,
        type : "product",
        added_via_api : false,
        deleted : false
      })
      let index = this.state.invoiceData.length;
      returnState.invoiceData = [...this.state.invoiceData, ...invoiceData];
      returnState['type-' + index] = 'product';
      returnState['product-' + index] = '';
      returnState['service-' + index] = '';
      returnState['quantity-' + index] = 1;
      returnState['rate-' + index] = 0;
      returnState['amount-' + index] = 0;
      returnState['discount-' + index] = 0;
      returnState['tax-' + index] = 0;
      returnState['tax-value-' + index] = 0;
      returnState['userid-' + index] = this.state.default_user_id;
      this.setState(returnState, () => {
        this.fullCalculationForInternal(this.state.discount_number);
      });
     
  }

  handleSaveSubmit = (id) => {
      let invoiceData = [];
      let error = false;
      let returnState = {};
    //   check errors in the system
    this.state.invoiceData && this.state.invoiceData.length > 0 && this.state.invoiceData.map((data,index) => {
      returnState['quantity-error-' + index] = false;
        //productList selection error
        if(data.deleted == false){
        if(this.state['type-' + index] == 'product'){
            if(this.state['product-' + index] == '' || this.state['product-' + index] == null || this.state['product-' + index] == undefined){
                returnState['product-error-' + index] = true;
                this.setState(returnState);
                error = true
            } else {
                returnState['product-error-' + index] = false;
                this.setState(returnState);
            }
        } 
        //serviceList selection error
        if(this.state['type-' + index] == 'service'){
            if(this.state['service-' + index] == '' || this.state['service-' + index] == null || this.state['service-' + index] == undefined){
                returnState['service-error-' + index] = true;
                this.setState(returnState);
                error = true
            } else {
                returnState['service-error-' + index] = false;
                this.setState(returnState);
            }
        }

        if(this.state['quantity-' + index] == '' || this.state['quantity-' + index] == null || this.state['quantity-' + index] == undefined || this.state['quantity-' + index] == 0 || !isPositiveNumber(this.state['quantity-' + index], null, null, 4)){ 
          returnState['quantity-error-' + index] = true;
          this.setState(returnState);
          error = true;
        }

        if(this.state['type-' + index] == 'service' && (this.state['quantity-' + index] % 1 !== 0)){
          returnState['quantity-error-' + index] = true;
          this.setState(returnState);
          error = true;
        }

        if(this.state['tax-' + index] === '' || this.state['tax-' + index] === null || this.state['tax-' + index] === undefined){
            returnState['tax-error-' + index] = true;
            this.setState(returnState);
            error = true;
        } else {
            if(this.state['tax-' + index] >= 0){
            returnState['tax-error-' + index] = false;
            this.setState(returnState);
            } else {
            returnState['tax-error-' + index] = true;
            this.setState(returnState);
            error = true;
            }
        }
        if(this.state['discount-' + index] === '' || this.state['discount-' + index] === null || this.state['discount-' + index] === undefined){
          returnState['discount-error-' + index] = true;
          this.setState(returnState);
          error = true;
        } else{
          returnState['discount-error-' + index] = false;
          this.setState(returnState);
        }

        const isStringAmount = typeof this.state['amount-' + index] === 'string';
        if(
          this.state['amount-' + index] < 0
          || this.state['amount-' + index] === ''
          || (isStringAmount && this.state['amount-' + index].trim() === '')
          || this.state['amount-' + index] === undefined)
        {
          returnState['amount-error-' + index] = true;
          this.setState(returnState);
          error = true
        } else {
          returnState['amount-error-' + index] = false;
          this.setState(returnState);
        }
      

     let amount = 0;
     let tax = 0;
     if(this.state['type-' + index] == 'product' && this.state['bill-for-whole-unit-' + index] == 1){
      amount = Math.ceil(this.state['quantity-' + index]) * this.state['rate-' + index];
   } else {
      amount = this.state['quantity-' + index] * this.state['rate-' + index];
     }
    
    amount = this.state['discount-' + index] > 0 ? amount - this.state['discount-' + index] : amount;
    tax = this.state['tax-' + index] > 0 ? (this.state['tax-' + index]/100) * amount : 0;
    amount = amount + tax;
    if(amount < 0){
      returnState['discount-error-' + index] = true;
      error = true;
    }
  }
    })
  


    if(error){
        return;
    }


    let totalTax = 0;
    let totalDiscount = 0;
      this.state.invoiceData && this.state.invoiceData.length > 0 && this.state.invoiceData.map((data,index) => {
        if(data.deleted == false){
        totalTax = totalTax + Number(this.state['tax-value-' + index]);
        totalDiscount = totalDiscount + Number(this.state['discount-' + index]);
          invoiceData.push({
            id: this.state['type-' + index] == 'product' ? this.state['product-' + index] : this.state['service-' + index],
            name : this.state['type-' + index] == 'product' ? this.state['search_product_keyword' + index] : this.state['search_service_keyword' + index],
            quantity : this.state['quantity-' + index],
            rate : this.state['rate-' + index],
            amount : this.state['bill-for-whole-unit-' + index] == 1 ? (Math.ceil(this.state['quantity-' + index]) * this.state['rate-' + index]) : (this.state['quantity-' + index] * this.state['rate-' + index] ) ,
            discount: Number(this.state['discount-' + index]) > 0 ? Number(this.state['discount-' + index]).toFixed(2) : 0,
            tax: this.state['tax-' + index] > 0 ? Number(this.state['tax-value-' + index]).toFixed(5) : 0,
            type :this.state['type-' + index] == 'product' ? 'product' : 'service',
            user_id : this.props.procedureData?.data?.user?.id || this.state['userid-' + index],
          })
        }
      })
      if(this.state.discount_number > this.state.subTotal - totalDiscount + totalTax){
        uiNotification.clear();
        uiNotification.error('Discount cannot be greater than total amount');
        return;
      }
      if (this.state.discount_error) {
        return;
      }
      let formData = {
        invoice_details: invoiceData,
        invoice_number : this.state.invoice_number,
        discount_type : this.state.discountType == 'dollar' ? 'dollar' : this.state.discountType == 'percentage' ? 'percentage' : '',
        discount_value : this.state.discount_number && this.state.discount_number > 0 ? this.state.discount_number : 0,
        subtotal : roundOffNum(this.state.subTotal, 2),
        subTotal_discount : totalDiscount,
        total : this.state.total.toFixed(2),
        invoice_id : this.state.invoice_id,
        total_tax : totalTax.toFixed(2),
        discount_percentage : this.state.discountType == 'dollar' ? 0 : this.state.discountType == 'percentage' ? this.state.discount : 0,
        shopify_dont_deduct_stock: this.state.shopify_dont_deduct_stock ? 1 : 0,  
      }
      if(id == 1 || id == 2){
        this.setState({ showLoader : true })
      } else {
          this.setState({ showSaveButton : true})
      }

      if(id == 1){
        formData.take_payment = 0;
        this.props.updateInvoice(formData, this.state.procedureID);
      } else if(id == 2){
        formData.take_payment = 1;
        this.props.takePayment(formData, this.state.procedureID)
      } else {
        formData.take_payment = 0;
        this.props.saveWhileWorking(formData, this.state.procedureID);
      }
  }


  deleteInvoiceRow = (data,index) => {
      let type = this.state['type-' + index];
      this.setState({deleteType : type,showDeleteModal : true , deleteNumber : index}, ()=> {})
    }

    deleteInvoice = () => {
        let invoiceData = this.state.invoiceData;
        let returnState = {};
        let keys = [];
        let subTotal = 0;
        let discount = 0;
        let total_discount = 0;
        invoiceData && invoiceData.length > 0 && invoiceData.map((data,index) => {
          if(data.deleted == false){
            keys.push(index);
            // subTotal = subTotal + Number(this.state['quantity-' + index] * this.state['rate-' * index]);
          }
        })
        if(keys.length > 1){
            invoiceData[this.state.deleteNumber].deleted = true;
            subTotal = Number(this.state.subTotal) - Number(this.state['quantity-' + this.state.deleteNumber] *  this.state['rate-' + this.state.deleteNumber]);
            returnState.invoiceData = invoiceData;
            returnState.showDeleteModal = false;
            invoiceData.map((data,index) => {
              if(data.deleted == false){
               total_discount = total_discount + Number(this.state['discount-' + index]);
              }
          })
          returnState.subTotal = subTotal;
          subTotal = subTotal - total_discount;
            if(this.state.discountType == 'dollar'){
              discount = this.state.discount;
            } else if(this.state.discountType == 'percentage'){
              discount = (subTotal * this.state.discount)/100;
            }
           
            returnState.discount_number = discount;
            this.setState(returnState,() => {this.fullCalculationForInternal(this.state.discount_number)})
         
        } else{
          returnState.showDeleteModal = false;
          uiNotification.clear();
          uiNotification.error('Atleast one product/service is required.');
          this.setState(returnState);
        }
    }

    dismissModal = () => {
        this.setState({ showDeleteModal : false });
    }


    clearAllData = () => {
        let returnState = {};
        this.state.invoiceData && this.state.invoiceData.length > 0 && this.state.invoiceData.map((data,index) => {
            returnState['type-' + index] = 'product';
            returnState['product-' + index] = '';
            returnState['service-' + index] = '';
            returnState['quantity-' + index] = 1;
            returnState['rate-' + index] = 0;
            returnState['amount-' + index] = 0;
            returnState['discount-' + index] = 0;
            returnState['tax-' + index] = 0;
            returnState['tax-value-' + index] = 0;
        })
        let invoiceData = [{
            id: '',
            name :'',
            quantity: '',
            rate: '',
            amount: '',
            discount: '',
            tax: '',
            type : "product",
            added_via_api : false,
            deleted : false
        }]
            returnState['type-0'] = 'product';
            returnState['search_product_keyword0'] = '';
            returnState['search_service_keyword0'] = '';
            returnState['product-0'] = '';
            returnState['service-0'] = '';
            returnState['quantity-0'] = 1;
            returnState['rate-0'] = 0;
            returnState['amount-0'] = 0;
            returnState['discount-0'] = 0;
            returnState['tax-0'] = 0;
            returnState['tax-value-0'] = 0;
            returnState['type-error-0'] = false;
            returnState['product-error-0'] = false;
            returnState['service-error-0'] = false;
            returnState['quantity-error-0'] = false;
            returnState['discount-error-0'] = false;
            returnState['tax-error-0'] = false;
            returnState['userid-0'] = this.state.default_user_id;
            returnState.discountType = 'dollar';
            returnState.total = 0;
            returnState.subTotal = 0;
            returnState.discount_type = '';
            returnState.subTotal_discount = 0;
            returnState.discount = 0;
            returnState.invoiceData = invoiceData;
            this.setState(returnState, () => {this.calculateTotal()});
    }

    goBackToClientScreen = () => {
        this.props.history.push(`/clients/profile/${this.state.patientData.id}`)
    }

    // Product Input Change start
    handleProductChange = (event,index) => {
        const target = event.target;
        let value = target.value;
        let name = event.target.name;
        let returnState = {}
    
        returnState[event.target.name] = value;
        returnState['is_product_search' + index] = true
        returnState['searched_product_id' + index] = 0
        returnState['searched_product_name' + index] = ''
        this.setState(returnState);
        this.searchProductByName(value,index)
      }

      searchProductByName = (value,index) => {
          let returnState = {};
        let formData = {
            type : 'product',
            patient_id : this.state.patientData.id,
            clinic_id : this.state.clinic.id
         }
       
        if (typeof value === 'string') {
          value = value.trim()
          if (value.length >= 3) {
            formData.search = value.trim();
            value = value.trim()
            this.props.searchProductForInvoice(formData,'product');
          } else {
              returnState['is_search_first_time' + index] = true;
              returnState.productList = [];
              returnState.searchIndex = index;
            this.setState(returnState)
          }
        }
      }
      handleOnFocus = (mode, index, event) => {
        //this.searchProductByName(this.state.search_product_keyword)
        let returnState = {};
        returnState['is_search_first_time' + index] = true;
        returnState.productList = []
        this.setState(returnState)
      }

      selectProduct = (event,index,obj) => {
        let returnState = {};
        let amount = 0;
        let tax = 0;
        let discount = 0;
        let total_discount = 0;
        let subtotal = 0;
        const target = event.target;
        let name = event.currentTarget.dataset.name;
        let id = event.currentTarget.dataset.id;
        returnState['searched_product_id' + index] = id;
        returnState['searched_product_name' + index] = name;
        returnState['search_product_keyword' + index] = name;
        returnState['is_product_search' + index] = false;
        returnState['rate-' + index] = obj.price_per_unit;
        returnState['product-' + index] = obj.id;
        returnState['tax-' + index] = obj.tax_rate_percentage;
        returnState['bill-for-whole-unit-' + index] = obj.bill_for_whole_unit;
        subtotal = Number(this.state.subTotal) + Number(obj.price_per_unit);
        returnState.subTotal = subtotal;
        this.state.invoiceData.map((data,index) => {
          if(data.deleted == false){
           total_discount = total_discount + Number(this.state['discount-' + index]);
          }
      })
      subtotal = subtotal - total_discount;
        if(this.state.discountType == 'dollar'){
          discount = this.state.discount;
        } else {
          discount = (subtotal * this.state.discount)/100
        }
        returnState.discount_number = discount;
        this.setState(returnState, () => {
          this.fullCalculationForInternal(this.state.discount_number)
          // this.internalCalculations('product',this.state['quantity-' + index], obj.price_per_unit, obj.tax_rate_percentage, this.state['discount-' + index], obj.bill_for_whole_unit,index)
        });
    
      }


       // Service input change start


      handleServiceChange = (event,index) => {
        const target = event.target;
        let value = target.value;
        let name = event.target.name;
        let returnState = {}
    
        returnState[event.target.name] = value;
        returnState['is_service_search' + index] = true
        returnState['searched_service_id' + index] = 0
        returnState['searched_service_name' + index] = ''
        this.setState(returnState);
        this.searchServiceByName(value,index)
      }

      searchServiceByName = (value,index) => {
        let returnState = {};
      let formData = {
          type : 'service',
          patient_id : this.state.patientData.id,
          clinic_id : this.state.clinic.id
       }
     
      if (typeof value === 'string') {
        value = value.trim()
        if (value.length >= 3) {
          formData.search = value.trim();
          value = value.trim()
          this.props.searchProductForInvoice(formData,'services');
        } else {
            returnState['is_search_first_time_service' + index] = true;
            returnState.serviceList = [];
            returnState.searchIndex = index;
          this.setState(returnState)
        }
      }
    }

    handleServiceOnFocus = (mode, index, event) => {
        //this.searchProductByName(this.state.search_product_keyword)
        let returnState = {};
        returnState['is_search_first_time_service' + index] = true;
        returnState.serviceList = []
        this.setState(returnState)
      }


      selectService = (event,index,obj) => {
      let returnState = {};
      let amount = 0;
      let tax = 0;
      let discount = 0;
      let total_discount = 0;
      let subtotal = 0;
      const target = event.target;
      let name = event.currentTarget.dataset.name;
      let id = event.currentTarget.dataset.id;
      returnState['searched_service_id' + index] = id;
      returnState['searched_service_name' + index] = name;
      returnState['search_service_keyword' + index] = name;
      returnState['is_service_search' + index] = false;
      returnState['rate-' + index] = obj.price;
      returnState['service-' + index] = obj.id;
      subtotal = Number(this.state.subTotal) + Number(obj.price);
      returnState.subTotal = subtotal;
      this.state.invoiceData.map((data,index) => {
        if(data.deleted == false){
         total_discount = total_discount + Number(this.state['discount-' + index]);
        }
    })
    subtotal = subtotal - total_discount;
      if(this.state.discountType == 'dollar'){
        discount = this.state.discount;
      } else {
        discount = (subtotal * this.state.discount)/100
      }
      returnState.discount_number = discount;
      this.setState(returnState, () => {
        this.fullCalculationForInternal(this.state.discount_number);
        // this.internalCalculations('service',this.state['quantity-' + index], obj.price, this.state['tax-' + index], this.state['discount-' + index], 0,index)
      });
  
    }

    changeQuantity = (index) =>{
      let returnState = {};
      if(this.state['bill-for-whole-unit-' + index] == 1 && this.state['type-' + index] == 'product'){
        returnState['quantity-' + index] = Math.ceil(this.state['quantity-' + index]);
        this.setState(returnState, () => {
          this.calculateTotal()
        });
      }
    }
  


render(){
    let returnUrl = '';
    if(this.state.patientData){
        returnUrl = '/clients/profile/' + this.state.patientData.id;
    }
    return(
        <div id="content" className="content-client-documents">
        <div className="container-fluid content setting-wrapper">
          <div className="juvly-section full-width m-t-15">
            <div className="juvly-container">
              <div className="juvly-title m-b-40">{!this.state.invoice_id ? 'Create Invoice' : (this.state.invoice_status != 'draft') ? 'View Invoice' : 'Update Invoice'}
                <Link to={returnUrl} className="pull-right"><img src="/images/close.png" /></Link>
    <span className="CreateInvoiceTotalAmount">Total: <b>{numberFormat(this.state.total,'currency',2)}</b></span>
              </div>

              <div className='row'>
						<div className="col-sm-4 col-xs-12">
							<label className="popup-input-field-name">Invoice No</label>
							<span className="createInviceDetail">{this.state.invoice_number}</span>
						</div>
						<div className="col-sm-4 col-xs-12">
							<label className="popup-input-field-name">Client</label>
							<span className="createInviceDetail">{this.state.patientData && this.state.patientData.firstname ? this.state.patientData.firstname + ' ' + this.state.patientData.lastname : ''}</span>
						</div>
						<div className="col-sm-4 col-xs-12">
							<label className="popup-input-field-name">Phone</label>
							<span className="createInviceDetail">{(this.state.patientData && this.state.patientData.phoneNumber) ? this.state.patientData.phoneNumber : 'N/A'}</span>
						</div>
						<div className="col-sm-8 col-xs-12">
							<label className="popup-input-field-name">Address</label>
							<span className="createInviceDetail">{this.state.patientAddress && this.state.patientAddress.length > 0 ? this.state.patientAddress.join(', ') : 'N/A'}</span>
						</div>
                        <div className="col-sm-4 col-xs-12">
							<label className="popup-input-field-name">Clinic</label>
							<span className="createInviceDetail">{this.state.clinic ? shortenClinicName(this.state.clinic.clinic_name) : ''}</span>
						</div>
                <div className="p-r-5 p-l-5">
                {this.state.invoice_status == 'draft' && getIsPosEnabled() &&
                  <button className="new-blue-btn pull-right flex align-center" disabled={this.state.showSaveButton} id="editsave" onClick={() => this.handleSaveSubmit(2)}>Take Payment &nbsp;&nbsp; {this.state.showSaveButton && <CircularProgress size="tiny" color="white" />}</button>}

                  <div className="table-responsive m-t-20 HealthInvoiceTable">
              <table className="table-updated setting-table min-w-1000  no-td-border">
              <thead className="table-updated-thead">
            <tr className="table-updated-row">
              
              <th className="col-xs-2 table-updated-th">Select</th>
              <th className="col-xs-4 table-updated-th">Product/Service</th>
              <th className="col-xs-1 table-updated-th">Quantity</th>
              <th className="col-xs-1 table-updated-th">Rate ({getCurrencySymbol()})</th>
              <th className="col-xs-1 table-updated-th p-r-0">Amount ({getCurrencySymbol()})</th>
              <th className="col-xs-1 table-updated-th p-r-0">Discount ({getCurrencySymbol()})</th>
              <th className="col-xs-1 table-updated-th">Tax (%)</th>
              <th className="col-xs-1 table-updated-th">Action</th>
            </tr>
          </thead>
                <tbody>
                    {this.state.invoiceData && this.state.invoiceData.length > 0 && this.state.invoiceData.map((data,index) =>{
                    return(
                    <tr className={data.deleted == true ? 'no-display' : "table-updated-row"} key={index}>
                    <td className="col-xs-2 table-updated-td">
                    <div className = 'newInputFileldOuter'>
                    <select name={'type-' + index} disabled={this.state.invoice_status == 'draft' ? false : true} value={this.state['type-' +index]} className={this.state['type-error-' + index] == true ? 'newSelectField field_error hours-pm to_time_option p-l-10' :'newSelectField hours-pm to_time_option p-l-10' } onChange={this.handleInputChange}>
					<option value='product'>Products</option>
                    <option value='service'>Services</option>			
					</select>
                    </div>
                    </td>

                     <td className='col-xs-4 table-updated-td'>
                    <div className = 'newInputFileldOuter'>
                {/* product */}
                  {this.state['type-' + index] == 'product' && <div className="search_product_container" >
                    <div className="simpleField m-t-0">
                      <input type="text" readOnly={this.state.invoice_status == 'draft' ? false : true}
                        className={this.state['product-error-' + index] == true ? 'newInputField field_error' : 'newInputField'}
                        autoComplete="off" placeholder='Search Product'
                        name={'search_product_keyword' + index}
                        value={this.state['search_product_keyword' + index]}
                        onChange={(e) => this.handleProductChange(e,index)}
                        onFocus={() => this.handleOnFocus(index)}
                      />
                    </div>
                    <ul className={(typeof this.state['search_product_keyword' + index] === 'string' && this.state['search_product_keyword' + index].trim() !== '') && (this.state['is_product_search' + index] !== undefined && this.state['is_product_search' + index] === true && this.state['search_product_keyword' + index].length > 2) ? " search-dropdown" : "cal-dropdown clinicname-dropdown no-display"}>
                      {(this.state.productList.length > 0) ? this.state.productList.map((obj, idx) => {
                        return (
                          <li key={"search_product-" + idx} data-id={obj.id} data-name={obj.product_name} onClick={(e) => this.selectProduct(e,index,obj)}>
                            <a >
                              {obj && obj.product_name}
                            </a>
                          </li>
                        )
                      })
                        :
                        <li key={"search_product-norecord"} data-id={0} data-name={'product_match_not_found'} data-index={-1}>
                          <a >
                            {(this.state['is_search_first_time' + index]) ? this.state.globalLang.label_searching_with_dot : this.state.globalLang.product_match_not_found}
                          </a>
                        </li>
                      }
                    </ul>
                  </div>}

                  
                  {/* services */}
                  {this.state['type-' + index] == 'service' && <div className="search_product_container" >
                    <div className="simpleField m-t-0">
                      <input type="text" readOnly={this.state.invoice_status == 'draft' ? false : true}
                        className={this.state['service-error-' + index] == true ? 'newInputField field_error' : 'newInputField'}
                        autoComplete="off" placeholder='Search Service'
                        name={'search_service_keyword' + index}
                        value={this.state['search_service_keyword' + index]}
                        onChange={(e) => this.handleServiceChange(e,index)}
                        onFocus={() => this.handleServiceOnFocus(index)}
                      />
                    </div>
                    <ul className={(typeof this.state['search_service_keyword' + index] === 'string' && this.state['search_service_keyword' + index].trim() !== '') && (this.state['is_service_search' + index] !== undefined && this.state['is_service_search' + index] === true && this.state['search_service_keyword' + index].length > 2) ? " search-dropdown" : "cal-dropdown clinicname-dropdown no-display"}>
                      {(this.state.serviceList.length > 0) ? this.state.serviceList.map((obj, idx) => {
                        return (
                          <li key={"search_service-" + idx} data-id={obj.id} data-name={obj.name} onClick={(e) => this.selectService(e,index,obj)}>
                            <a >
                              {obj && obj.name}
                            </a>
                          </li>
                        )
                      })
                        :
                        <li key={"search_product-norecord"} data-id={0} data-name={'product_match_not_found'} data-index={-1}>
                          <a >
                            {(this.state['is_search_first_time_service' + index]) ? this.state.globalLang.label_searching_with_dot : this.state.globalLang.product_match_not_found}
                          </a>
                        </li>
                      }
                    </ul>
                  </div>}





                </div>

                    </td>

                    <td className='col-xs-1 table-updated-td'>
                    <div className="newInputFileldOuter">
                    <div className="setting-input-outer">
                    <input name={'quantity-' + index} onBlur={() => this.changeQuantity(index)} readOnly={((this.state['product-' + index] || this.state['service-' + index]) && this.state.invoice_status == 'draft') ? false: true} type='text' className={this.state['quantity-error-' + index] == true ? 'newInputField field_error p-l-10 p-r-10' : 'newInputField p-l-10 p-r-10'}  value={this.state['quantity-' + index]} onChange={this.handleInputChange} autoComplete="off" /></div>
                    </div>
                    </td>

                    <td className='col-xs-1 table-updated-td'>
                    <div className="newInputFileldOuter">
                    <div className="setting-input-outer">
                    <input name={'rate-' + index} readOnly={true} className="newInputField p-l-10 p-r-10" type="text" value={this.state['rate-'+ index]}  autoComplete="off" /></div>
                    </div>
                    </td>

                    <td className='col-xs-1 table-updated-td'>
                    <div className="newInputFileldOuter">
                    <div className="setting-input-outer">
                    <input name={'amount-' + index} readOnly={true} className={this.state['amount-error-' + index]== true ? "newInputField field_error p-l-10 p-r-10" : "newInputField  p-l-10 p-r-10"} type="text" value={this.state['amount-' + index]}  autoComplete="off" /></div>
                    </div>
                    </td>

                    <td className='col-xs-1 table-updated-td'>
                    <div className="newInputFileldOuter">
                    <div className="setting-input-outer">
                    <input name={'discount-' + index} readOnly={this.state.invoice_status == 'draft' ? false : true} className={this.state['discount-error-' + index] == true ? 'newInputField field_error p-l-10 p-r-10' : 'newInputField p-l-10 p-r-10'} type="text" value={this.state['discount-' + index]} onChange={this.handleInputChange} autoComplete="off" /></div>
                    </div>
                    </td>

                    <td className='col-xs-1 table-updated-td'>
                    <div className="newInputFileldOuter">
                    <div className="setting-input-outer">
                    <input name={'tax-'+ index} readOnly={(this.state.invoice_status == 'draft' && this.state['type-' + index] == 'service') ? false : true} className={this.state['tax-error-' + index] == true ? 'newInputField field_error p-l-10 p-r-10': 'newInputField p-l-10 p-r-10'} type="text" value={this.state['tax-' + index]} onChange={this.handleInputChange} autoComplete="off" /></div>
                    </div>
                    </td>

                    {this.state.invoice_status == 'draft' ? <td className="col-xs-1 table-updated-td " ><a className="easy-link m-t-15 p-l-0" onClick={() => this.deleteInvoiceRow(data,index)}
                       data-title="Delete User"><i className="fa fa-trash m-r-5"  />Delete</a></td> : <td className="col-xs-1 table-updated-td " ></td>}
                       </tr>
                        )
                    })}
                    
                       </tbody>
                    </table>
                    </div>
				</div>

                    <div className='col-xs-12 col-lg-12 m-t-10 p-l-0 p-r-0'>
                {this.state.invoice_status == 'draft' && <a className="easy-link" onClick={() => this.addNewLine()}>Add Lines</a>}
                {this.state.invoice_status == 'draft' && <a className="easy-link" onClick={() => this.clearAllData()}>Clean All Lines</a>}  
                    </div>

                    <div className='col-xs-12 col-lg-12 m-t-10 p-l-0 p-r-0'>
						<div className='col-lg-2 col-sm-4 col-xs-12 pull-right'>
							<div className="newInputFileldOuter">
									<div className="newInputLabel">Value</div>
									<div className="setting-input-outer">
										<input name="discount" className={this.state.discount_error==true ? "newInputField field_error" : "newInputField"} readOnly={this.state.invoice_status == 'draft' ? false : 'true'} type="text" value={this.state.discount} onChange={this.handleInputChange} autoComplete="off" />
									</div>
							</div>
						</div>
						<div className='col-lg-3 col-sm-4  col-xs-12 pull-right'>
							<div className ='newInputFileldOuter'>
								<div className="newInputLabel">Discount Value</div>
								<select class="newSelectField hours-pm to_time_option" disabled={this.state.invoice_status == 'draft' ? false : true} value={this.state.discountType} name='discountType' onChange={this.handleDiscountChange}>
								<option value='dollar'>Number</option>
								<option value='percentage'>Percentage</option>			
								</select>
							</div>
						</div>
						
                    </div>

                    <div class="col-lg-3 col-sm-4 col-xs-12 m-t-10 pull-right purchaseOrderInfo">
                        <div class="detail-row">
                            <label>Subtotal ({getCurrencySymbol()})</label>
                            <span>{numberFormat(this.state.subTotal,'',2)}</span>
                            </div>
                            
                            <div class="detail-row">
                            <label>Discount ({getCurrencySymbol()}):</label>
                            <span>{numberFormat(this.state.subTotal_discount,'',2)}</span>
                            </div>

                            <div class="detail-row">
                            <label>Tax ({getCurrencySymbol()}):</label>
                            <span>{numberFormat(this.state.total_tax,'',2)}</span>
                            </div>
                                
                            <div class="detail-row">
                            <label>Total ({getCurrencySymbol()}):</label>
                            <span>{numberFormat(this.state.total,'',2)}</span></div>
                            
                            </div>
                    </div>
                      {Boolean(this.props.isShopifyEnabled && isShopifyAvailable()) && (
                        <div className="row pull-right p-r-117">
                          <Checkbox
                            label="Fulfill with Shopify"
                            isChecked={!!this.state.shopify_dont_deduct_stock} 
                            onChange={() => this.setState({
                              shopify_dont_deduct_stock: !this.state.shopify_dont_deduct_stock
                            })} 
                          />
                        </div>
                      )}
              </div>
              {this.state.invoice_status == 'draft' ? <div className="footer-static">
              {getIsPosEnabled() && 
                <button 
                  className="new-blue-btn pull-right flex align-center" 
                  disabled={this.state.showSaveButton} id="editsave" 
                  onClick={() => this.handleSaveSubmit(2)}>Take Payment &nbsp;&nbsp; {this.state.showSaveButton && <CircularProgress size="tiny" color="white" />}
                </button>}
              {this.state.showSaveButton == false && <input className="new-blue-btn pull-right" onClick={() => this.handleSaveSubmit(1)} id="editsave" type="submit" value="Save" />}
              {this.state.showSaveButton == true && <a className='new-blue-btn pull-right'>Saving&nbsp;&nbsp;<i className='fa fa-spinner fa-spin'></i></a>}
                  
              <button type="button" onClick={() => this.goBackToClientScreen()} className={"new-red-btn pull-left"} id="resetform">{/*this.state.Appointment_Reminder_Inactive*/ "Cancel"}</button>
                  </div> :<div className="footer-static"></div> }
              </div>
              
              </div>
              {/* </div> */}
              

              {/* Modal to delete any product */}
              <div className={(this.state.showDeleteModal ? 'overlay' : '')}></div>
                  <div id="filterModal" role="dialog" className={(this.state.showDeleteModal ? 'modal fade in displayBlock' : 'modal fade')}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" onClick={this.dismissModal}>×</button>
                          <h4 className="modal-title" id="model_title">Confirmation Required!!</h4>
                        </div>
                        <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                          {`Are you sure you want to delete this ${this.state.deleteType}? The ${this.state.deleteType} will only be deleted once you save the invoice.`}
                        </div>
                        <div className="modal-footer" >
                          <div className="col-md-12 text-left" id="footer-btn">
                            <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.dismissModal}>{this.state.settingsLang.no_option}</button>
                            <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.deleteInvoice}>{this.state.settingsLang.yes_option}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Loader className='new-loader text-left fixed' isFullWidth={true} showLoader={this.state.showLoader} />
              </div>

    )
}
}

function mapStateToProps(state){
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    let returnState = {};
    if(state.ClientsReducer.action == 'GET_PROCEDURE_INVOICE'){
    if(state.ClientsReducer.data.status == 200 ){
        returnState.invoiceData = state.ClientsReducer.data.data;
        returnState.invoiceDataTime = new Date();
    } else {
        uiNotification.clear();
        uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
    }
}

    if(state.ClientsReducer.action == 'SAVE_HEALTH_PROCEDURE_INVOICE'){
        if(state.ClientsReducer.data.status == 200 ){
           uiNotification.clear();
           uiNotification.success(languageData.global[state.ClientsReducer.data.message]);
           returnState.redirectToClients = true;
           returnState.saveHealthInvoiceDataTime = new Date();
        } else {
            uiNotification.clear();
            uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
        }
    }

    if ( state.ProcedureReducer.action === 'GET_PROCEDURE_DATA' ) {
        if ( state.ProcedureReducer.data.status !== 200 ) {
            uiNotification.error(languageData.global[state.ProcedureReducer.data.message])
        } else {
            returnState.procedureData = state.ProcedureReducer.data
        }
    }

    if(state.ClientsReducer.action == 'TAKE_PAYMENT_INVOICE'){
        if(state.ClientsReducer.data.status == 200 ){
            returnState.redirect = true;
            returnState.invoice = state.ClientsReducer.data.data;
            returnState.takePaymentDataTime = new Date();
         } else {
             uiNotification.clear();
             uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
         }
    }

    if(state.ClientsReducer.action == 'SAVE_WHILE_WORKING'){
        if(state.ClientsReducer.data.status == 200 ){
            returnState.savingWhileWorking = true;
            returnState.savingWhileWorkingTime = new Date();
         } else {
            
         }
    }

    if(state.ClientsReducer.action == 'SEARCH_PRODUCT_FOR_INVOICE'){
        if(state.ClientsReducer.data.status == 200){
            returnState.productsData = state.ClientsReducer.data.data;
            returnState.productsDataTime = new Date();
        } else {
            uiNotification.clear();
            uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
        }
    }

    if(state.ClientsReducer.action == 'SEARCH_SERVICE_FOR_INVOICE'){
        if(state.ClientsReducer.data.status == 200){
            returnState.serviceData = state.ClientsReducer.data.data;
            returnState.serviceDataTime = new Date();
        } else {
            uiNotification.clear();
            uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
        }
    }
    return returnState;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getProcedureData: getProcedureData, getInvoiceData: getInvoiceData, updateInvoice, takePayment: takePayment,saveWhileWorking : saveWhileWorking, searchProductForInvoice: searchProductForInvoice}, dispatch)
  }
  
export default connect(mapStateToProps,mapDispatchToProps)(withShopifyEnabled(HealthInvoiceCreation));
