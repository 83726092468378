import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { DateRangePicker } from "react-date-range";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchProviderSalesData } from "../../../Actions/Dashboard/dashboardActions.js";
import { showFormattedDate, numberFormat } from "../../../Utils/services.js";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import calenLogo from "../../../_legacy/images/calender.svg";
import { WidgetLoader } from "../components/WidgetLoader.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class WidgetProviderSales extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const prosalesDate = JSON.parse(localStorage.getItem("prosalesDate"));
    const valR = prosalesDate ? prosalesDate : "";
    const startDate = valR.startDate;
    const endDate = valR.endDate;
    let date = new Date();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    date =
      date.getFullYear() +
      "-" +
      month +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    this.state = {
      globalLang: languageData.global,
      dashProviderData: [],
      providerSalesList: [],
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      lastPage: 1,
      page: 1,
      pagesize: 6,
      perPage: 6,
      totalNum: 1,
      dateRangePicker: {
        selection: {
          startDate: startDate ? startDate : date,
          endDate: endDate ? endDate : date,
          key: "selection",
        },
      },
      to_date: date,
      from_date: date,
      showCalendar: false,
      term: "",
      clicked: 0,
      pageNumber: [],
      showLoader2: false,
      activePage: 1,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
    const dateData = JSON.parse(localStorage.getItem("prosalesDate"));
    let formData = {
      params: {
        term: this.state.term,
        page: this.state.page,
        pagesize: this.state.pagesize,
        from_date: dateData ? dateData.startDate : this.state.from_date,
        to_date: dateData ? dateData.endDate : this.state.to_date,
      },
    };
    this.setState({
      showLoader2: true,
      to_date: dateData ? dateData.endDate : this.state.to_date,
      from_date: dateData ? dateData.startDate : this.state.from_date,
    });

    this.props.fetchProviderSalesData(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.dashProviderData != undefined &&
      nextProps.dashProviderData.data != prevState.dashProviderData.data &&
      nextProps.dashProviderData.status == 200 &&
      prevState.providerTime != nextProps.providerTime
    ) {
      returnState.dashProviderData = nextProps.dashProviderData.data;
      returnState.showLoader2 = false;
    }
    return returnState;
  }

  handleInputChange = () => {
    let returnState = {};
    returnState.dataChanged = true;
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.nodeSalesByProvider) {
      if (
        this.nodeSalesByProvider.contains(e.target) &&
        this.state.showCalendar === true
      ) {
        return;
      } else if (
        this.nodeSalesByProvider.contains(e.target) &&
        this.state.showCalendar === false
      ) {
        this.setState({ showCalendar: true });
      } else if (
        !this.nodeSalesByProvider.contains(e.target) &&
        this.state.showCalendar === true
      ) {
        this.setState({ showCalendar: false });
      }
    }
  };

  toggleCalendar = (elem) => {
    if (this.nodeSalesByProvider) {
      if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
        return;
      }

      let showCalendar = false;

      if (
        this.state.showCalendar === false &&
        elem.name !== undefined &&
        elem.name === "calendar-input"
      ) {
        showCalendar = true;
      } else {
        showCalendar = false;
      }

      this.setState({ showCalendar: showCalendar });
    }
  };

  handleSubmit = (event, value) => {
    if (typeof event === "object") {
      event.preventDefault();
    }
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
        from_date:
          value != undefined && value.from_date != undefined
            ? value.from_date
            : this.state.from_date,
        to_date:
          value != undefined && value.to_date != undefined
            ? value.to_date
            : this.state.to_date,
      },
    };
    this.setState({
      activePage: 1,
      startFresh: true,
      loadMore: true,
      dashProviderData: [],
      showLoader2: true,
      innerbox_select_all: false,
    });

    this.props.fetchProviderSalesData(formData);
  };

  handleRangeChange = (which, payload) => {
    let save_start_date = "";
    let save_end_date = "";
    if (payload) {
      let date_1 = new Date(payload.selection.startDate);
      let date_2 = new Date(payload.selection.endDate);
      let month_1 = date_1.getMonth() + 1;
      let month_2 = date_2.getMonth() + 1;
      let day_1 = date_1.getDate();
      let day_2 = date_2.getDate();
      if (month_1 < 10) {
        month_1 = "0" + month_1;
      }
      if (month_2 < 10) {
        month_2 = "0" + month_2;
      }
      day_1 = day_1 < 10 ? "0" + day_1 : day_1;
      day_2 = day_2 < 10 ? "0" + day_2 : day_2;
      save_start_date = date_1.getFullYear() + "-" + month_1 + "-" + day_1;
      save_end_date = date_2.getFullYear() + "-" + month_2 + "-" + day_2;
      let payloadValue = {
        startDate: save_start_date,
        endDate: save_end_date,
        key: "selection",
      };
      localStorage.setItem("prosalesDate", JSON.stringify(payloadValue));
    }
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    startDate = save_start_date;
    endDate = save_end_date;
    let clicked = this.state.clicked + 1;
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }
    let showCalendar = true;
    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      showCalendar: showCalendar,
      from_date: startDate,
      to_date: endDate,
      clicked: clicked,
    });

    if (clicked && clicked % 2 === 0) {
      this.handleSubmit(which, { from_date: startDate, to_date: endDate });
    }
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  render() {
    let viewData = [];
    let currentPage = this.state.activePage;
    let limit = 6;
    let last = this.state.dashProviderData.length;
    let totalNum = Math.ceil(this.state.dashProviderData.length);
    let pData = viewData;
    let startIndex = (currentPage - 1) * limit;
    let endIndex = limit + startIndex;

    if (endIndex > last) {
      endIndex = last;
    }
    for (let i = startIndex; i < endIndex; i++) {
      viewData.push(this.state.dashProviderData[i]);
    }

    return (
      <div className="setting-setion m-b-15">
        <div className="dashboardSectionTitle">
          <b>Total Sales Per Provider</b>
          <div className="memberRightActions">
            <div
              className="search-bg new-calender pull-left"
              ref={(nodeSalesByProvider) => {
                this.nodeSalesByProvider = nodeSalesByProvider;
              }}
            >
              <img src={calenLogo} alt="" />
              {this.state.showCalendar && (
                <DateRangePicker
                  ranges={[
                    {
                      ...this.state.dateRangePicker.selection,
                      startDate: new Date(
                        `${this.state.dateRangePicker.selection.startDate} 00:00:00`,
                      ),
                      endDate: new Date(
                        `${this.state.dateRangePicker.selection.endDate} 00:00:00`,
                      ),
                    },
                  ]}
                  onChange={this.handleRangeChange.bind(
                    this,
                    "dateRangePicker",
                  )}
                  className={"CalendarPreviewArea"}
                  maxDate={new Date()}
                  dragSelectionEnabled={false}
                  id={"drpSalesByProvider"}
                  ref={(refSalesByProvider) => {
                    this.refSalesByProvider = refSalesByProvider;
                  }}
                />
              )}
              <input
                type="text"
                className="input-cal setting-search-input p-b-5"
                name="calendar-input"
                value={
                  this.state.from_date
                    ? showFormattedDate(this.state.from_date, false) +
                      `-` +
                      showFormattedDate(this.state.to_date, false)
                    : ""
                }
                autoComplete="off"
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="table-responsive dashboardSectionContent">
          <table className="records-table">
            <thead>
              <tr className={pData.length ? "" : "no-display"}>
                <th className="col-xs-8 no-border">Provider</th>
                <th className="text-left col-xs-4 no-border">Amount</th>
              </tr>
            </thead>
            <tbody>
              {pData &&
                pData.map((obj, idx) => {
                  let userImage =
                    obj.user == null
                      ? "/images/user.png"
                      : obj.user.user_image_url
                      ? obj.user.user_image_url
                      : "/images/user.png";
                  return (
                    <tr key={idx}>
                      <td className="col-xs-8">
                        <div
                          className="user-profile-img"
                          style={{ backgroundImage: `url(${userImage})` }}
                        ></div>
                        {obj.provider == null ? " " : obj.provider}
                      </td>
                      <td className="text-left col-xs-4">
                        {numberFormat(obj.gross_sales, "currency")}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {
            <div
              className={pData.length ? "no-record no-display" : "no-record"}
            >
              {this.state.globalLang.sorry_no_record_found}
            </div>
          }
        </div>
        <div className="dashboardSectionFooter">
          <div className="dashboardPagination">
            {pData.length > 0 && (
              <Pagination
                prevPageText={"Prev"}
                nextPageText={"Next"}
                firstPageText={<i className="fa fa-angle-double-left" />}
                lastPageText={<i className="fa fa-angle-double-right" />}
                activePage={this.state.activePage}
                itemsCountPerPage={6}
                totalItemsCount={totalNum}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            )}
          </div>
        </div>
        {this.state.showLoader2 && <WidgetLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.DashboardReducer.action === "DASHBOARD_PROVIDER_SALES_DATA") {
    if (state.DashboardReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.DashboardReducer.data.message],
      );
    } else {
      returnState.dashProviderData = state.DashboardReducer.data;
      returnState.providerTime = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProviderSalesData: fetchProviderSalesData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetProviderSales);
