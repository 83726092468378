/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  showFormattedDate,
  numberFormat,
  isPositiveNumber,
} from "../../Utils/services.js";
import {
  getTraceData,
  getBatchByProcedure,
  getUnits,
  saveTrace,
  updateTrace,
  deleteTrace,
  searchProduct,
  saveTraceProducts,
} from "../../Actions/Traceability/traceActions.js";
import picClose from "../../_legacy/images/close.png";
import { getPrecision, isNullish, toFixed } from "../../utilities/general.js";
import { Input } from "../../shared/Input/Input.js";
import { InputLabel } from "../../shared/InputLabel/InputLabel.js";
import { SelectNative } from "../../shared/SelectNative/SelectNative.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { isDerivedFromIncrement } from "../../helpers/general.js";
import { DeleteTraceability } from "./shared/DeleteTraceability/DeleteTraceability.js";
import { ProcedureChartingProductTraceability } from "./shared/ProcedureChartingProductTraceability/ProcedureChartingProductTraceability.js";
import { ProcedureRetailProductTraceability } from "./shared/ProcedureRetailProductTraceability/ProcedureRetailProductTraceability.js";

class TraceabilityInfo extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      backURLType: this.props.match.params.actionType
        ? this.props.match.params.actionType
        : "clients",
      action: this.props.match.params.type
        ? this.props.match.params.type
        : "profile",
      clientID: this.props.match.params.clientID,
      procedureID: this.props.match.params.procedureID,
      globalLang: languageData.global,
      showLoader: false,
      traceData: [],
      showTraceSection: false,
      productForTrace: 0,
      chartingPackageID: null,
      traceToDelete: 0,
      productToDelete: 0,
      traceMode: null,
      batchData: [],
      dataChanged: false,
      batch_id: 0,
      batch_units: 0,
      traceID: 0,
      saveTraceStatus: null,
      updateTraceStatus: null,
      deleteTraceStatus: null,
      languageData: languageData.clients,
      subType: this.props.match.params.subType
        ? this.props.match.params.subType
        : "",
      add_product: false,
      settingsLang: languageData.settings,
      unitMin: undefined,
      unitMax: undefined,
      unitStep: undefined,
    };

    window.onscroll = () => {
      return false;
    };
  }

  fetchTraceData = (id, procedureId) => {
    this.setState({ showLoader: true });
    this.props
      .getTraceData(id, procedureId)
      .catch((res) => {
        uiNotification.error(
          this.state.globalLang[res.message] || "Unable to fetch data",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  componentDidMount = () => {
    this.fetchTraceData(this.state.clientID, this.state.procedureID);
  };

  componentDidUpdate = (_, prevState) => {
    if (
      this.state.saveTraceStatus !== null &&
      this.state.saveTraceStatus !== "" &&
      this.state.saveTraceStatus !== prevState.saveTraceStatus
    ) {
      this.fetchTraceData(this.state.clientID, this.state.procedureID);
      this.setState({ showLoader: true, traceMode: null });
    }

    if (
      this.state.updateTraceStatus !== null &&
      this.state.updateTraceStatus !== "" &&
      this.state.updateTraceStatus !== prevState.updateTraceStatus
    ) {
      this.fetchTraceData(this.state.clientID, this.state.procedureID);
      this.setState({ showLoader: true, traceMode: null });
    }

    if (
      this.state.deleteTraceStatus !== null &&
      this.state.deleteTraceStatus !== "" &&
      this.state.deleteTraceStatus !== prevState.deleteTraceStatus
    ) {
      this.fetchTraceData(this.state.clientID, this.state.procedureID);
      this.setState({
        showLoader: true,
        traceToDelete: 0,
        productToDelete: 0,
        showModal: false,
      });
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });

    if (event.target.name === "batch_id") {
      this.getUnitsByInventory(target.value);
    }

    if (target.name.startsWith("quantity-")) {
      let index = target.name.split("-")[1];
      let productsData = this.state.productsData;
      productsData[index].quantity = value;
      this.setState({ productsData: productsData }, () => {});
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.showLoader != undefined && props.showLoader == false) {
      return { showLoader: false };
    }
    if (
      props.traceData !== undefined &&
      props.traceData.status === 200 &&
      props.traceData.data !== state.traceData
    ) {
      return {
        traceData: props.traceData.data,
        showLoader: false,
        toxinTypes: props.traceData.data.toxin_types,
        showTraceSection: false,
      };
    }

    if (
      props.batchData !== undefined &&
      props.batchData.status === 200 &&
      props.batchData.data.batch_data !== state.batchData
    ) {
      if (props.batchData.data.batch_data.length) {
        return {
          batchData: props.batchData.data.batch_data,
          batch_id: props.batchData.data.selected_batch,
          batch_units: props.batchData.data.selected_unit,
          showLoader: false,
          showTraceSection: true,
        };
      } else {
        uiNotification.clear();
        uiNotification.error("No data found");
        return {
          batchData: props.batchData.data.batch_data,
          showLoader: false,
          traceMode: null,
        };
      }
    }

    if (
      props.saveTraceData !== undefined &&
      props.saveTraceData !== null &&
      props.saveTraceData.status === 200 &&
      props.saveTraceData.data !== state.saveTraceData
    ) {
      return {
        saveTraceData: props.saveTraceData.data,
        saveTraceStatus: props.saveTraceData.data,
      };
    }

    if (
      props.updateTraceData !== undefined &&
      props.updateTraceData !== null &&
      props.updateTraceData.status === 200 &&
      props.updateTraceData.data !== state.updateTraceData
    ) {
      return {
        updateTraceData: props.updateTraceData.data,
        updateTraceStatus: props.updateTraceData.data,
      };
    }

    if (
      props.deleteTraceData !== undefined &&
      props.deleteTraceData !== null &&
      props.deleteTraceData.status === 200 &&
      props.deleteTraceData.data !== state.deleteTraceData
    ) {
      return {
        deleteTraceData: props.deleteTraceData.data,
        deleteTraceStatus: props.deleteTraceData.data,
      };
    }

    if (
      props.productsData != undefined &&
      props.productsData != state.productsData &&
      props.productsDataTime != state.productsDataTime
    ) {
      let returnState = {};
      returnState.productsDataTime = props.productsDataTime;
      returnState.productList = props.productsData.data;
      returnState["is_search_first_time" + state.searchIndex] =
        props.productsData.data.length == 0 ? false : true;
      return returnState;
    }

    if (
      props.returnBackToInjections != undefined &&
      props.returnBackToInjections == true &&
      props.returnTimeStamp != state.returnTimeStamp
    ) {
      let returnState = {};
      returnState.returnTimeStamp = props.returnTimeStamp;
      returnState.productsData = [];
      props.getTraceData(state.clientID, state.procedureID);
      return returnState;
    }

    return null;
  }

  addUpdateTraceInfo = (productID, traceID, chartingPackageID) => {
    if (this.state.showTraceSection == false) {
      let type = "";

      if (traceID > 0) {
        type = "update";
        this.setState({
          showLoader: true,
          productForTrace: productID,
          chartingPackageID: chartingPackageID,
          traceMode: type,
          traceID: traceID,
        });
      } else {
        type = "add";
        this.setState({
          showLoader: true,
          productForTrace: productID,
          chartingPackageID: chartingPackageID,
          traceMode: type,
          traceID: 0,
        });
      }

      this.props
        .getBatchByProcedure(productID, this.state.procedureID, type, traceID)
        .then((res) => {
          this.setState({
            unitMin: res.data.min_unit ? Number(res.data.min_unit) : undefined,
            unitMax: res.data.max_unit ? Number(res.data.max_unit) : undefined,
            unitStep: res.data.count_units_by
              ? Number(res.data.count_units_by)
              : undefined,
          });
        })
        .catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] ||
              "Unable to get batch by procedure",
          );
        });
    }
  };

  cancelAddEditTrace = () => {
    this.setState({
      showTraceSection: false,
      traceMode: null,
      productForTrace: 0,
      chartingPackageID: null,
      batch_id: 0,
      batch_units: 0,
      unitMin: undefined,
      unitMax: undefined,
      unitStep: undefined,
    });
  };

  showDeleteTraceModal = (traceID, productID) => {
    if (traceID) {
      this.setState({
        traceToDelete: traceID,
        productToDelete: productID,
        showModal: true,
      });
    }
  };

  dismissModal = () => {
    this.setState({ traceToDelete: 0, productToDelete: 0, showModal: false });
  };

  deleteTraceData = () => {
    if (
      (this.state.traceToDelete,
      this.state.productToDelete,
      this.state.procedureID)
    ) {
      let formData = {
        procedure_id: this.state.procedureID,
        product_id: this.state.productToDelete,
        trace_id: this.state.traceToDelete,
      };

      this.setState({ showModal: false, showLoader: true });
      this.props
        .deleteTrace(formData)
        .then((res) => {
          uiNotification.success(
            this.state.globalLang[res.message] || "Successfully deleted",
          );
        })
        .catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] || "Unable to delete",
          );
        });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.batch_id > 0 && this.state.batch_units > 0) {
      const units = Number(this.state.batch_units);

      if (this.state.unitMin && units < this.state.unitMin) {
        return uiNotification.error(
          "Units should be more than or equal to " + this.state.unitMin,
        );
      }

      if (this.state.unitMax && units > this.state.unitMax) {
        return uiNotification.error(
          "Units should be less than or equal to " + this.state.unitMax,
        );
      }

      if (
        this.state.unitStep &&
        !isDerivedFromIncrement(units, this.state.unitStep)
      ) {
        return uiNotification.error(
          "Units should be multiple of " + this.state.unitStep,
        );
      }

      if (this.state.traceMode && this.state.traceMode === "add") {
        let formData = {
          inventory_id: this.state.batch_id,
          units: this.state.batch_units,
          procedure_id: this.state.procedureID,
          product_id: this.state.productForTrace,
          charting_package_id: this.state.chartingPackageID,
        };

        this.props
          .saveTrace(formData)
          .then((res) => {
            uiNotification.success(
              this.state.globalLang[res.message] || "Successfully saved",
            );
            this.setState({
              unitMin: undefined,
              unitMax: undefined,
              unitStep: undefined,
            });
          })
          .catch((res) => {
            uiNotification.error(
              this.state.globalLang[res.message] || "Unable to save",
            );
          });
      } else {
        let formData = {
          inventory_id: this.state.batch_id,
          units: this.state.batch_units,
          procedure_id: this.state.procedureID,
          product_id: this.state.productForTrace,
          trace_id: this.state.traceID,
        };

        this.props
          .updateTrace(formData)
          .then((res) => {
            uiNotification.success(
              this.state.globalLang[res.message] || "Data saved successfully.",
            );
            this.fetchTraceData(this.state.clientID, this.state.procedureID);
            this.setState({
              unitMin: undefined,
              unitMax: undefined,
              unitStep: undefined,
            });
          })
          .catch((res) => {
            uiNotification.error(
              this.state.globalLang[res.message] || "Unable to save data.",
            );
          })
          .finally(() => {
            this.setState({ showLoader: false });
          });
      }
      this.setState({ showLoader: true });
    } else {
      if (
        this.state.traceData &&
        this.state.traceData.procedure_data &&
        this.state.traceData.procedure_data.type &&
        this.state.traceData.procedure_data.type === "coolsculpting"
      ) {
        uiNotification.error("Please select Serial No and Units");
      } else {
        uiNotification.error("Please select Batch ID and Units");
      }
    }
  };

  getUnitsByInventory = (inventoryID) => {
    if (inventoryID > 0) {
      let formData = {
        inventory_id: inventoryID,
        old_trace: this.state.traceID,
        procedure_id: this.state.procedureID,
        product_id: this.state.productForTrace,
        charting_package_id: this.state.chartingPackageID,
      };

      this.setState({ showLoader: true });
      this.props
        .getUnits(formData)
        .then((res) => {
          this.setState({
            unitMin: Number(res.data.min_unit),
            unitMax: Number(res.data.max_unit),
            unitStep: Number(res.data.count_units_by),
          });
        })
        .catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] || "Unable to get units",
          );
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
    }
  };

  addProductSection = () => {
    let returnState = {};
    let productList = [
      {
        product_id: "",
        product_name: "",
        quantity: "",
        deleted: false,
      },
    ];
    returnState["product-error-0"] = false;
    returnState["search_product_keyword0"] = "";
    returnState["quantity-0"] = "";
    returnState["quantity-error-0"] = false;
    returnState.productList = [];
    returnState.productsData = productList;
    returnState.add_product = true;
    this.setState(returnState);
  };

  handleProductChange = (event, index) => {
    const target = event.target;
    let value = target.value;
    let returnState = {};

    returnState[event.target.name] = value;
    returnState["is_product_search" + index] = true;
    returnState["searched_product_id" + index] = 0;
    returnState["searched_product_name" + index] = "";
    this.setState(returnState);
    this.searchProductByName(value, index);
  };

  searchProductByName = (value, index) => {
    let returnState = {};
    let formData = { params: { limit: 20 } };
    let selected_product_ids = [];

    this.state.productsData.map((obj) => {
      if (obj.product_id) selected_product_ids.push(obj.product_id);
    });

    if (selected_product_ids.length) {
      let formIds = selected_product_ids.join(",");
      formData.params.selected_product_ids = formIds;
    }

    if (typeof value === "string") {
      value = value.trim();
      if (value.length >= 3) {
        formData.search = value.trim();
        formData.product_type = "injectable";
        value = value.trim();
        this.props.searchProduct(formData).catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] || "Unable to find a product",
          );
        });
      } else {
        returnState["is_search_first_time" + index] = true;
        returnState.productList = [];
        returnState.searchIndex = index;
        this.setState(returnState);
      }
    }
  };

  handleOnFocus = (_, index) => {
    let returnState = {};
    returnState["is_search_first_time" + index] = true;
    returnState.productList = [];
    this.setState(returnState);
  };

  selectProduct = (event, index) => {
    let returnState = {};
    let name = event.currentTarget.dataset.name;
    let id = event.currentTarget.dataset.id;

    returnState["searched_product_id" + index] = id;
    returnState["searched_product_name" + index] = name;
    returnState["search_product_keyword" + index] = name;
    returnState["is_product_search" + index] = false;

    let productValue = this.state.productsData;

    productValue[index].product_id = id;
    productValue[index].product_name = name;
    returnState.productsData = productValue;

    this.setState(returnState);
  };

  addNewLine = () => {
    let productsData = [];
    let returnState = {};

    productsData.push({
      product_id: "",
      product_name: "",
      quantity: "",
      deleted: false,
    });

    let index = this.state.productsData.length;

    returnState.productsData = [...this.state.productsData, ...productsData];
    returnState["quantity-" + index] = "";
    returnState["quantity-error-" + index] = false;
    returnState["product-error-" + index] = false;
    returnState["search_product_keyword" + index] = "";

    this.setState(returnState);
  };

  saveProducts = () => {
    this.setState({ showLoader: false });

    let error = false;
    let product_id = [];
    let units = [];
    let returnState = {};

    this.state.productsData &&
      this.state.productsData.length > 0 &&
      this.state.productsData.map((data, index) => {
        if (data.deleted == false) {
          if (
            data.quantity == "" ||
            data.quantity.trim() == "" ||
            data.quantity == 0 ||
            data.quantity == "0" ||
            !isPositiveNumber(data.quantity, null, null, 4)
          ) {
            returnState["quantity-error-" + index] = true;
            this.setState(returnState);
            error = true;
          } else {
            returnState["quantity-error-" + index] = false;
            this.setState(returnState);
          }
          if (data.product_id == "" || data.product_id.trim() == "") {
            returnState["product-error-" + index] = true;
            this.setState(returnState);
            error = true;
          } else {
            returnState["product-error-" + index] = false;
            this.setState(returnState);
          }
          product_id.push(data.product_id);
          units.push(data.quantity);
        }
      });
    if (error) {
      return;
    }

    let formData = {
      product_id: product_id,
      units: units,
      procedure_id: this.state.procedureID,
    };

    this.setState({ add_product: false, showLoader: true });
    this.props.saveTraceProducts(formData).catch((res) => {
      uiNotification.error(
        this.state.globalLang[res.message] || "Unable to save",
      );
    });
  };

  deleteRow = (_, index) => {
    this.setState({ showDeleteModal: true, deleteNumber: index }, () => {});
  };

  deleteInvoice = () => {
    let productsData = this.state.productsData;
    let returnState = {};

    productsData[this.state.deleteNumber].deleted = true;
    returnState.productsData = productsData;
    returnState.showDeleteModal = false;

    let check = productsData.find((y) => y.deleted == false);

    if (!check) {
      returnState.add_product = false;
      returnState.productsData = [];
    }

    this.setState(returnState);
  };

  dismissModal = () => {
    this.setState({ showDeleteModal: false });
  };

  cancelProducts = () => {
    this.setState({ add_product: false, productsData: [] });
  };

  closeDeleteModal = () => {
    this.setState({ traceToDelete: 0, productToDelete: 0, showModal: false });
  };

  onChangeBatchUnits = (e) => {
    const value = e.target.value;

    if (getPrecision(Number(value)) > 3) {
      return;
    }

    this.setState({ batch_units: value });
  };

  render() {
    let returnTo = "";

    if (this.state.backURLType && this.state.backURLType === "clients") {
      returnTo = this.props.match.params.type
        ? "/" +
          this.state.backURLType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.clientID
        : "/" + this.state.backURLType;
    } else {
      returnTo = this.props.match.params.type
        ? "/" +
          this.state.backURLType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.procedureID +
          "/" +
          this.props.match.params.subType
        : "/" + this.state.backURLType + "/" + this.props.match.params.subType;
    }

    let procedureType =
      this.state.traceData && this.state.traceData.procedure_data
        ? this.state.traceData.procedure_data.type
        : "";

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="juvly-section full-width m-t-15">
            <div className="juvly-container">
              <div className="juvly-title m-b-0">
                {this.state.languageData.clientprofile_trace_info}

                <Link to={returnTo} className="pull-right p-l-10">
                  <img src={picClose} alt="" />
                </Link>
                {this.state.subType == "health" &&
                  this.state.add_product == false && (
                    <a
                      className="new-blue-btn pull-right trac-info-btn"
                      onClick={() => this.addProductSection()}
                    >
                      Add Traceability
                    </a>
                  )}
              </div>

              {/* Table starts */}
              {this.state.add_product == true && (
                <div className="col-lg-8 m-t-20 bordersides">
                  <div className="table-responsive  HealthInvoiceTable">
                    <table className="table-updated setting-table no-td-border">
                      <thead className="table-updated-thead">
                        <tr className="table-updated-row">
                          <th className="col-xs-4 table-updated-th">Product</th>
                          <th className="col-xs-2 table-updated-th">
                            Quantity
                          </th>
                          <th className="col-xs-3 table-updated-th">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.productsData &&
                          this.state.productsData.length > 0 &&
                          this.state.productsData.map((data, index) => {
                            return (
                              <tr
                                className={
                                  data.deleted == true
                                    ? "no-display"
                                    : "table-updated-row"
                                }
                                key={index}
                              >
                                <td className="col-xs-4 table-updated-td">
                                  <div className="newInputFileldOuter">
                                    <div className="search_product_container">
                                      <div className="simpleField m-t-0">
                                        <input
                                          type="text"
                                          className={
                                            this.state[
                                              "product-error-" + index
                                            ] == true
                                              ? "newInputField field_error"
                                              : "newInputField"
                                          }
                                          autoComplete="off"
                                          placeholder="Search Product"
                                          name={
                                            "search_product_keyword" + index
                                          }
                                          value={
                                            this.state[
                                              "search_product_keyword" + index
                                            ]
                                          }
                                          onChange={(e) =>
                                            this.handleProductChange(e, index)
                                          }
                                          onFocus={() =>
                                            this.handleOnFocus(index)
                                          }
                                        />
                                      </div>
                                      <ul
                                        className={
                                          typeof this.state[
                                            "search_product_keyword" + index
                                          ] === "string" &&
                                          this.state[
                                            "search_product_keyword" + index
                                          ].trim() !== "" &&
                                          this.state[
                                            "is_product_search" + index
                                          ] !== undefined &&
                                          this.state[
                                            "is_product_search" + index
                                          ] === true &&
                                          this.state[
                                            "search_product_keyword" + index
                                          ].length > 2
                                            ? " search-dropdown"
                                            : "cal-dropdown clinicname-dropdown no-display"
                                        }
                                      >
                                        {this.state.productList &&
                                        this.state.productList.length > 0 ? (
                                          this.state.productList.map(
                                            (obj, idx) => {
                                              return (
                                                <li
                                                  key={"search_product-" + idx}
                                                  data-id={obj.data.id}
                                                  data-name={
                                                    obj.data.product_name
                                                  }
                                                  onClick={(e) =>
                                                    this.selectProduct(
                                                      e,
                                                      index,
                                                      obj,
                                                    )
                                                  }
                                                >
                                                  <a>
                                                    {obj &&
                                                      obj.data.product_name}
                                                  </a>
                                                </li>
                                              );
                                            },
                                          )
                                        ) : (
                                          <li
                                            key={"search_product-norecord"}
                                            data-id={0}
                                            data-name={
                                              "product_match_not_found"
                                            }
                                            data-index={-1}
                                          >
                                            <a>
                                              {this.state[
                                                "is_search_first_time" + index
                                              ]
                                                ? this.state.globalLang
                                                    .label_searching_with_dot
                                                : this.state.globalLang
                                                    .product_match_not_found}
                                            </a>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                                <td className="col-xs-2 table-updated-td">
                                  <div className="newInputFileldOuter m-t-0">
                                    <div className="setting-input-outer">
                                      <input
                                        name={"quantity-" + index}
                                        type="text"
                                        className={
                                          this.state[
                                            "quantity-error-" + index
                                          ] == true
                                            ? "newInputField field_error p-l-10 p-r-10"
                                            : "newInputField p-l-10 p-r-10"
                                        }
                                        value={this.state["quantity-" + index]}
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className="col-xs-3 table-updated-td">
                                  <a
                                    className="easy-link p-l-0 marginSet"
                                    onClick={() => this.deleteRow(data, index)}
                                    data-title="Delete User"
                                  >
                                    <i className="fa fa-trash m-r-5" />
                                    Delete
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {this.state.productsData &&
                    this.state.productsData.length > 0 &&
                    this.state.add_product == true && (
                      <div className="col-xs-12 col-lg-12 m-t-10 p-l-0 p-r-0">
                        <a
                          className="easy-link"
                          onClick={() => this.addNewLine()}
                        >
                          Add More
                        </a>
                        <a
                          className="new-blue-btn pull-right trac-info-btn"
                          onClick={() => this.saveProducts()}
                        >
                          Save
                        </a>
                        <a
                          className="new-red-btn pull-right"
                          onClick={() => this.cancelProducts()}
                        >
                          Cancel
                        </a>
                      </div>
                    )}
                </div>
              )}
            </div>
            {this.state.traceData.injections !== undefined &&
              this.state.traceData.injections !== null &&
              this.state.traceData.injections.length > 0 &&
              this.state.traceData.injections.map((tobj, tidx) => {
                let unitsUsed = 0.0;
                let addTraceClass = "new-blue-btn pull-right trac-info-btn";

                const isProcedureChartingPackage =
                  ["medical_supplies", "retail"].includes(tobj.image_type) &&
                  Boolean(tobj.charting_package);

                const isProcedureRetailPackage =
                  tobj.image_type === "retail" && !tobj.charting_package;

                if (
                  tobj.trace_injection !== undefined &&
                  tobj.trace_injection !== null &&
                  tobj.trace_injection.length > 0
                ) {
                  tobj.trace_injection.map((traceInjObj) => {
                    unitsUsed += traceInjObj.units_consumed;
                  });

                  if (
                    parseFloat(numberFormat(tobj.quantity, "decimal")) <=
                    parseFloat(numberFormat(unitsUsed, "decimal"))
                  ) {
                    addTraceClass =
                      "new-blue-btn pull-right trac-info-btn disable";
                  }
                }

                if (
                  this.state.traceMode === "add" &&
                  this.state.productForTrace === tobj.product.id &&
                  this.state.chartingPackageID ===
                    (tobj.charting_package ? tobj.charting_package.id : null)
                ) {
                  addTraceClass =
                    "new-blue-btn pull-right trac-info-btn no-display";
                }

                if (isProcedureChartingPackage) {
                  return (
                    <div key={tidx} className="Traceability-section">
                      <ProcedureChartingProductTraceability
                        procedureId={tobj.procedure_id}
                        productId={tobj.product.id}
                        chartingPackageId={tobj.charting_package_id}
                        quantity={tobj.quantity}
                        index={tidx + 1}
                        min={tobj.product.start_at_unit}
                        step={tobj.product.count_units_by}
                        max={tobj.charting_package_product_max_units}
                        name={tobj.charting_package.name}
                        productName={tobj.product.product_name}
                        price={tobj.charting_package_product_price}
                        refetch={() =>
                          this.fetchTraceData(
                            this.state.clientID,
                            this.state.procedureID,
                          )
                        }
                      />
                    </div>
                  );
                }

                if (isProcedureRetailPackage) {
                  return (
                    <div key={tidx} className="Traceability-section">
                      <ProcedureRetailProductTraceability
                        procedureId={tobj.procedure_id}
                        productId={tobj.product.id}
                        quantity={tobj.quantity}
                        index={tidx + 1}
                        min={tobj.product.start_at_unit}
                        step={tobj.product.count_units_by}
                        productName={tobj.product.product_name}
                        refetch={() =>
                          this.fetchTraceData(
                            this.state.clientID,
                            this.state.procedureID,
                          )
                        }
                      />
                    </div>
                  );
                }

                return (
                  <div key={tidx} className="Traceability-section">
                    <div className="juvly-container">
                      <div className="juvly-subtitle no-margin">
                        <div className="d-flex align-center gap-8">
                          {++tidx}.{" "}
                          {tobj.charting_package
                            ? tobj.charting_package.name + " -"
                            : ""}{" "}
                          {tobj.product ? tobj.product.product_name : ""}{" "}
                          {tobj.quantity
                            ? toFixed(
                                Number(tobj.quantity),
                                getPrecision(
                                  Number(tobj.product.count_units_by),
                                ) || 1,
                              )
                            : ""}{" "}
                          {this.state.toxinTypes && tobj.product
                            ? this.state.toxinTypes[tobj.product.toxin_type]
                            : ""}
                          <DeleteTraceability
                            id={tobj.id}
                            onSuccess={() =>
                              this.fetchTraceData(
                                this.state.clientID,
                                this.state.procedureID,
                              )
                            }
                          />
                        </div>
                        {addTraceClass.indexOf("disable") === -1 ? (
                          <a
                            onClick={() =>
                              this.addUpdateTraceInfo(
                                tobj.product.id,
                                0,
                                tobj.charting_package
                                  ? tobj.charting_package.id
                                  : null,
                              )
                            }
                            className={addTraceClass}
                          >
                            {this.state.languageData.trace_add_trace_info}
                          </a>
                        ) : (
                          <a className={addTraceClass}>
                            {this.state.languageData.trace_add_trace_info}
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table-updated juvly-table no-hover">
                        <thead className="table-updated-thead">
                          <tr>
                            <th className="col-xs-2 table-updated-th">
                              {procedureType &&
                              procedureType === "coolsculpting"
                                ? "Serial No"
                                : this.state.languageData.trace_bacth_id}
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              {this.state.languageData.trace_expiration}
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              {this.state.languageData.trace_units}
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              {this.state.languageData.trace_price}
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              {this.state.languageData.trace_actions}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tobj.trace_injection !== undefined &&
                            tobj.trace_injection !== null &&
                            tobj.trace_injection.length > 0 &&
                            tobj.trace_injection.map(
                              (traceInjObj, traceInjIdx) => {
                                return (
                                  <tr
                                    key={traceInjIdx}
                                    className="table-updated-tr"
                                  >
                                    <td className="table-updated-td">
                                      {traceInjObj.product_inventory
                                        ? traceInjObj.product_inventory.batch_id
                                        : ""}
                                    </td>
                                    <td className="table-updated-td">
                                      {traceInjObj.product_inventory
                                        ? showFormattedDate(
                                            traceInjObj.product_inventory
                                              .expiry_date,
                                            false,
                                            "DD-MMM-YYYY",
                                          )
                                        : ""}
                                    </td>
                                    <td className="table-updated-td">
                                      {traceInjObj.units_consumed
                                        ? toFixed(
                                            Number(traceInjObj.units_consumed),
                                            getPrecision(
                                              Number(
                                                tobj.product.count_units_by,
                                              ),
                                            ) || 1,
                                          )
                                        : ""}
                                    </td>
                                    <td className="table-updated-td">
                                      {traceInjObj.price
                                        ? numberFormat(
                                            traceInjObj.price,
                                            "currency",
                                          )
                                        : ""}
                                    </td>
                                    <td className="table-updated-td">
                                      <a
                                        onClick={() =>
                                          this.addUpdateTraceInfo(
                                            tobj.product.id,
                                            traceInjObj.id,
                                            tobj.charting_package
                                              ? tobj.charting_package.id
                                              : null,
                                          )
                                        }
                                        className="easy-link"
                                      >
                                        {this.state.languageData.client_edit}
                                      </a>
                                      <a
                                        onClick={() =>
                                          this.showDeleteTraceModal(
                                            traceInjObj.id,
                                            tobj.product.id,
                                          )
                                        }
                                        className="easy-link"
                                      >
                                        {this.state.languageData.client_delete}
                                      </a>
                                    </td>
                                  </tr>
                                );
                              },
                            )}

                          <tr
                            className={
                              tobj.trace_injection === undefined ||
                              tobj.trace_injection.length === 0
                                ? "table-updated-tr"
                                : "table-updated-tr no-display"
                            }
                          >
                            <td colSpan="5" className="text-center">
                              {this.state.languageData.client_no_record_found}
                            </td>
                          </tr>

                          <tr
                            className={
                              this.state.showTraceSection &&
                              this.state.productForTrace === tobj.product.id &&
                              this.state.chartingPackageID ===
                                (tobj.charting_package
                                  ? tobj.charting_package.id
                                  : null)
                                ? "table-updated-tr editQuestion_tr"
                                : "table-updated-tr editQuestion_tr no-display"
                            }
                          >
                            <td colSpan="5">
                              <div className="setting-container bg-light-blue">
                                <div className="setting-title m-b-40">
                                  {this.state.traceMode &&
                                  this.state.traceMode === "add"
                                    ? "Add Traceability Info"
                                    : "Edit Traceability Info"}
                                  <a
                                    onClick={() => this.cancelAddEditTrace()}
                                    className="pull-right cancel_editAction"
                                  >
                                    <img src={picClose} alt="" />
                                  </a>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                  <div className="row">
                                    <div className="d-flex gap-24 flex-wrap m-b-20 p-l-15">
                                      <div>
                                        <InputLabel>
                                          {procedureType &&
                                          procedureType === "coolsculpting"
                                            ? "Serial No"
                                            : this.state.languageData
                                                .trace_bacth_id}
                                        </InputLabel>
                                        <div className="setting-input-outer">
                                          <SelectNative
                                            size="small"
                                            name="batch_id"
                                            onChange={this.handleInputChange}
                                            value={this.state.batch_id}
                                            options={[
                                              { id: "0", batch_id: "Select" },
                                              ...this.state.batchData,
                                            ].map((x) => ({
                                              value: x.id,
                                              label: x.batch_id,
                                            }))}
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <InputLabel>Units</InputLabel>
                                        <Input
                                          size="small"
                                          type="number"
                                          value={this.state.batch_units}
                                          onChange={this.onChangeBatchUnits}
                                        />
                                        {!isNullish(this.state.unitMin) && (
                                          <div className="font-12">
                                            Min: {this.state.unitMin}
                                          </div>
                                        )}
                                        {!isNullish(this.state.unitMax) && (
                                          <div className="font-12">
                                            Max: {this.state.unitMax}
                                          </div>
                                        )}
                                        {!isNullish(this.state.unitStep) && (
                                          <div className="font-12">
                                            Count by: {this.state.unitStep}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-xs-12">
                                      <a
                                        onClick={() =>
                                          this.cancelAddEditTrace()
                                        }
                                        className="new-white-btn cancel_editAction"
                                      >
                                        {
                                          this.state.languageData
                                            .clientprofile_cancel
                                        }
                                      </a>
                                      <input
                                        className="new-blue-btn"
                                        type="submit"
                                        id="save-profile"
                                        value={
                                          this.state.traceMode &&
                                          this.state.traceMode === "add"
                                            ? this.state.languageData.wallet_add
                                            : this.state.languageData
                                                .client_update
                                        }
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}

            <div
              className={this.state.showModal === true ? "overlay" : ""}
            ></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showModal === true
                  ? "modal fade in displayBlock"
                  : "modal fade no-display"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.closeDeleteModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title">
                      {this.state.languageData.client_conf_requierd}
                    </h4>
                  </div>
                  <div
                    id="errorwindow"
                    className="modal-body add-patient-form filter-patient"
                  >
                    {this.state.languageData.trace_delete_message}
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 text-left">
                      <button
                        type="button"
                        className="btn  logout pull-right"
                        data-dismiss="modal"
                        onClick={this.closeDeleteModal}
                      >
                        {this.state.languageData.client_no}
                      </button>
                      <button
                        type="button"
                        className="btn btn-success pull-right m-r-10"
                        data-dismiss="modal"
                        onClick={this.deleteTraceData}
                      >
                        {this.state.languageData.client_yes}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={this.state.showDeleteModal ? "overlay" : ""}></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showDeleteModal
                  ? "modal fade in displayBlock"
                  : "modal fade"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title" id="model_title">
                      Confirmation Required!!
                    </h4>
                  </div>
                  <div
                    id="errorwindow"
                    className="modal-body add-patient-form filter-patient"
                  >
                    {`Are you sure you want to delete this product?`}
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 text-left" id="footer-btn">
                      <button
                        type="button"
                        className="btn  logout pull-right"
                        data-dismiss="modal"
                        onClick={this.dismissModal}
                      >
                        {this.state.settingsLang.no_option}
                      </button>
                      <button
                        type="button"
                        className="btn btn-success pull-right m-r-10"
                        data-dismiss="modal"
                        onClick={this.deleteInvoice}
                      >
                        {this.state.settingsLang.yes_option}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                (this.state.traceData.injections === undefined ||
                  this.state.traceData.injections.length === 0) &&
                this.state.add_product == false
                  ? "no-record"
                  : "no-record no-display"
              }
            >
              {this.state.languageData.trace_no_injection}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock clientLoader clientProfileLoader"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
              alt=""
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.globalLang.loading_please_wait_text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.TraceReducer.action === "GET_TRACE_DATA") {
    if (state.TraceReducer.data.status === 200) {
      returnState.traceData = state.TraceReducer.data;
    }
  }

  if (state.TraceReducer.action === "GET_BATCH_BY_PROCEDURE") {
    if (state.TraceReducer.data.status !== 200) {
      returnState.showLoader = false;
    } else {
      returnState.batchData = state.TraceReducer.data;
    }
  }

  if (state.TraceReducer.action === "SAVE_TRACE") {
    if (state.TraceReducer.data.status !== 200) {
      returnState.showLoader = false;
    } else {
      returnState.saveTraceData = state.TraceReducer.data;
    }
  }

  if (state.TraceReducer.action === "UPDATE_TRACE") {
    if (state.TraceReducer.data.status !== 200) {
      returnState.showLoader = false;
    } else {
      returnState.updateTraceData = state.TraceReducer.data;
    }
  }

  if (state.TraceReducer.action === "DELETE_TRACE") {
    if (state.TraceReducer.data.status !== 200) {
      returnState.showLoader = false;
    } else {
      returnState.deleteTraceData = state.TraceReducer.data;
    }
  }

  if (state.TraceReducer.action === "SEARCH_TRACE_PRODUCTS") {
    if (state.TraceReducer.data.status === 200) {
      returnState.productsData = state.TraceReducer.data;
      returnState.productsDataTime = new Date();
    }
  }

  if (state.TraceReducer.action === "ADD_INJECTIONS") {
    if (state.TraceReducer.data.status === 200) {
      returnState.returnBackToInjections = true;
      returnState.returnTimeStamp = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTraceData,
      getBatchByProcedure,
      getUnits,
      saveTrace,
      updateTrace,
      deleteTrace,
      searchProduct,
      saveTraceProducts,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TraceabilityInfo);
