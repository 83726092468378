import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { makeDurationInTime } from "../../../Utils/dateHelper";
import { useStripe } from "../../../hooks/useStripe";
import { event } from "../../../store/event";
import WaitList from "./WaitList";
import Loader from "../components/Loader";
import { EntityRow } from "../components/EntityRow";
import CommonType from "../components/common/CommonType";
import CommonClinic from "../components/common/CommonClinic";
import CommonNotes from "../components/common/CommonNotes";
import CommonCCForm from "../components/common/CommonCCForm";
import CommonCCDetails from "../components/common/CommonCCDetails";
import AppointmentType from "../components/appointment/AppointmentType";
import AppointmentProvider from "../components/appointment/AppointmentProvider";
import AppointmentServices from "../components/appointment/AppointmentServices";
import AppointmentDateTime from "../components/appointment/AppointmentDateTime";
import AppointmentNotificationModal from "../components/appointment/AppointmentNotificationModal";
import AppointmentReschedulingModal from "../components/appointment/AppointmentReschedulingModal";
import { useApiAvailableDays } from "../hooks/useApiAvailableDays";
import { useApiClientById } from "../hooks/useApiClientById";
import { useApiClinicsByProvider } from "../hooks/useApiClinicsByProvider";
import { useApiPatientCardDetails } from "../hooks/useApiPatientCardDetails";
import { useApiProviders } from "../hooks/useApiProviders";
import { useApiServices } from "../hooks/useApiServices";
import { useApiAppointmentFees } from "../hooks/useApiAppointmentFees";
import { useApiPaymentStatuses } from "../hooks/useApiPaymentStatuses";
import {
  getCreateFormInitialDate,
  initAppointmentForm,
  useFormAppointment,
} from "../hooks/useFormAppointment";
import { useMutateAppointment } from "../hooks/useMutateAppointment";
import { useCreateAppointmentNotification } from "../hooks/useCreateAppointmentNotification";
import { usePaymentInfoAppointment } from "../hooks/usePaymentInfoAppointment";

import {
  APPOINTMENT_TYPES,
  EVENT_EDIT_MODES,
  TIME_END_DURATION_IN_MIN_FALLBACK,
} from "../Event.consts";
import {
  composeFormService,
  wrapFormDate,
  getCalendarStartDate,
  getPathParams,
} from "../Event.utils";
import classes from "../sass/Event.module.scss";
import CommonAdditionally from "../components/common/CommonAdditionally";
import { CommonControlButtons } from "../components/common/CommonControlButtons";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { AlertBox } from "../../../shared/AlertBox/AlertBox";
import ConfirmServicesDepositModal from "../components/appointment/ConfirmServicesDepositModal";
import { getUserPaymentSystem } from "../../../utilities/localStorage";
import { ClientHandler } from "../widgets/ClientHandler/ClientHandler";
import { ModalConfirmCreateExam } from "../components/ModalConfirmCreateExam";
import { ModalCreateQualiphyProcedure } from "../../../widgets/ModalCreateQualiphyProcedure/ModalCreateQualiphyProcedure";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { USER_PAYMENT_SYSTEMS } from "../../../consts/api";
import {
  getSetupIntent,
  getClientCardDataStripe,
} from "../../../Actions/Stripe/StripeActions";
import StripePaymentForm from "../../../Components/Sales/FrontdeskCheckout/StripePaymentForm";
import { Tabs } from "../../../shared/Tabs/Tabs";
import CommonCardList from "../components/common/CommonCardList";
import { usePaymentMethodsList } from "../hooks/usePaymentMethodsList";
import { DEFAULT_TIME_FORMAT } from "../../../consts/general";
import { useLeadAsClient } from "../hooks/useLeadAsClient";
import { useClientCreditCardsQuery } from "../../../api/queries/useClientCreditCardsQuery";
import { uiNotification } from "../../../services/UINotificationService";

/*
 * Leads flow:
 *
 * 1. If booking url has :leadId, then it's a lead booking;
 * 2. If it's lead booking then we need to fetch lead details and prefill
 *  the Patient form with lead data in readonly format;
 * 3. If it's lead booking then we need to hide Waitlist and OOO radio buttons;
 */

const Appointment = ({
  match: { params },
  history,
  editEventId,
  editEventData,
  eventType,
  isEventSubmitActive,
  changeEventType,
  changeIsSubmitActive,
  stripeIntent,
  getSetupIntent,
  cardList,
  getClientCardDataStripe,
}) => {
  const [cardSelected, setCardSelected] = useState("");
  const [newCard, setNewCard] = useState(false);

  const { leadId } = getPathParams();

  const isEditMode = Boolean(editEventId);
  const isRescheduleMode = Boolean(params.mode === EVENT_EDIT_MODES.reschedule);
  const { data: currentUser } = useCurrentUserQuery();

  const stripeCardNumberRef = useRef();
  const stripe = useStripe();

  const [notification, setNotification] = useState(null);
  const [createdAppointmentId, setCreatedAppointmentId] = useState(null);
  const [isCancelationFeeModalOpen, setIsCancelationModalOpen] =
    useState(false);

  const [
    isConfirmExcludingCancellationPolicyModalOpen,
    setIsConfirmExcludingCancellationPolicyModalOpen,
  ] = useState(false);

  const [confirmServicesDepositModal, setConfirmServicesDepositModal] =
    useState({ open: false, hasAlreadyConfirmedUserChoice: false });

  const [isCreateExamModalOpen, setIsCreateExamModalOpen] = useState(false);
  const [isConfirmCreateExamModalOpen, setIsConfirmCreateExamModalOpen] =
    useState(false);

  const [savedPaymentMethodView, setSavedPaymentMethodView] = useState("card");

  const { clientById, isClientByIdFetching } = useApiClientById(
    params.clientId,
  );

  const lead = useLeadAsClient();

  const { form, setFormValue, hasError, submit } = useFormAppointment(
    initAppointmentForm({
      userPaymentSystem: getUserPaymentSystem(),
      clientById: leadId ? lead.value : clientById,
      editEventData,
      params,
      history,
      userTimeFormat: currentUser?.timeFormat || DEFAULT_TIME_FORMAT,
    }),
  );

  const { providers, isProvidersFetching } = useApiProviders();

  const { clinics, isClinicsFetching } = useApiClinicsByProvider({
    providerId: form.providerId,
  });

  const { services, clearentClinic, isServicesFetching } = useApiServices({
    providerId: form.providerId,
    clinicId: form.clinicId,
    type: form.type,
  });

  const {
    availableDays,
    isAvailableDaysFetching,
    getAvailableHoursByDate,
    getAvailableHoursInsideScheduleByDate,
  } = useApiAvailableDays({
    type: form.type,
    clinicId: form.clinicId,
    services: form.services,
    isDoubleBooking: form.isDoubleBooking,
    isOutsideScheduledHours: form.isOutsideScheduledHours,
    providerId: form.providerId,
    calendarStartDate: form.calendarStartDate,
    editEventId,
  });

  const { data: clientCC } = useClientCreditCardsQuery({
    clientId: form.clientId,
  });

  const patientCCOptions = useMemo(
    () =>
      clientCC?.creditCards?.map((card) => ({
        label: card.cardNumber,
        value: card.clearentCardNumber,
      })),
    [clientCC],
  );

  const { patientCardDetails, isPatientCardDetailsFetching } =
    useApiPatientCardDetails({
      patientId: form.clientId,
    });

  const { paymentStatuses, isPaymentStatusesFetching } =
    useApiPaymentStatuses();

  const {
    isClinicCanTakePayment,
    isCanTakePaymentWithDeposit,
    isCanTakePaymentWithCancelationPolicy,
    isCancellationPolicyTipVisible,
  } = usePaymentInfoAppointment({
    paymentStatuses,
    services: form.services,
    clinicId: form.clinicId,
    clinics,
  });

  const { appointmentFees, isAppointmentFeesFetching } = useApiAppointmentFees({
    appointmentId: editEventId,
  });

  const availableHoursByDate = useMemo(
    () => getAvailableHoursByDate(form.date),
    [form.date, getAvailableHoursByDate],
  );

  const availableHoursInsideScheduleByDate = useMemo(
    () => getAvailableHoursInsideScheduleByDate(form.date),
    [form.date, getAvailableHoursInsideScheduleByDate],
  );

  const { mutateAppointment, isAppointmentMutating } = useMutateAppointment({
    providers,
    availableHoursByDate,
    isCanTakePaymentWithDeposit,
    isCanTakePaymentWithCancelationPolicy,
    clientCardNumber: patientCardDetails.cardNumber,
    selectedClearentCard: form.selectedClearentCard?.value || null,

    setNotification,
    resetClient: () => {
      setFormValue("clientName", "");
      setFormValue("clientId", "");
    },
    editEventId,
    confirmCancellationPolicyExcluding: () =>
      setIsConfirmExcludingCancellationPolicyModalOpen(true),

    onSuccess: (response) => {
      setCreatedAppointmentId(response?.appointmentId);
    },
    isConvertingWaitlist: Boolean(form.isConvertingWaitlist),
    stripeIntent,
    cardSelected,
    newCard,
  });

  const { createAppointmentNotification, isAppointmentNotificationCreating } =
    useCreateAppointmentNotification();

  const isLoading =
    isProvidersFetching ||
    isClinicsFetching ||
    isServicesFetching ||
    isAvailableDaysFetching ||
    isClientByIdFetching ||
    lead.isLoading ||
    isPatientCardDetailsFetching ||
    isPaymentStatusesFetching ||
    isAppointmentMutating ||
    isAppointmentNotificationCreating ||
    isAppointmentFeesFetching;

  const isCancelationFeeIncluded = appointmentFees.cancelationFee > 0;

  const cancelRoute = params.clientId
    ? `/clients/profile/${params.clientId}`
    : "/appointment/index";

  const userPaymentSystem = getUserPaymentSystem();

  const { linkList, cardsList, haveLinkAndCardSaved } =
    usePaymentMethodsList(cardList);

  /* AppointmentType */

  const onTypeChange = (nextType) => {
    setFormValue("type", nextType);
    setFormValue("clinicId", "");
    setFormValue("services", []);
    setFormValue("date", getCreateFormInitialDate(params));
    setFormValue("timeStart", "");
  };

  /* Provider */

  const onProviderIdChange = (nextProviderId) => {
    setFormValue("providerId", nextProviderId);
    setFormValue("clinicId", "");
    setFormValue("services", []);
    setFormValue("date", getCreateFormInitialDate(params));
    setFormValue("timeStart", "");
  };

  /* Clinic */

  const onClinicIdChange = (nextClinicId) => {
    setFormValue("clinicId", nextClinicId);
    setFormValue("services", []);
    setFormValue("date", getCreateFormInitialDate(params));
    setFormValue("timeStart", "");
  };

  /* Services */

  const onSelectService = ({ service, index, isDepositConfirmed }) => {
    const nextService = composeFormService({
      id: service.id,
      name: service.name,
      durationInMin: service.durationInMin,
      isDepositConfirmed: isDepositConfirmed || false,
      isFree: service.isFree,
    });
    const nextServices = [...form.services];
    nextServices[index] = nextService;
    setFormValue("services", nextServices);
  };

  const onRemoveService = (index) => {
    setFormValue(
      "services",
      form.services.filter((_, i) => i !== index),
    );
  };

  const onEditService = (index, data) => {
    const nextServices = [...form.services];
    if (nextServices[index]) {
      nextServices[index] = {
        ...nextServices[index],
        ...data,
      };
      setFormValue("services", nextServices);
    }
  };

  /* Converting Waitlist Services Confirm Deposit */

  const handleCloseServiceDepositModal = () => {
    setConfirmServicesDepositModal({
      open: false,
      hasAlreadyConfirmedUserChoice: true,
    });
  };

  const updateServices = (services) => {
    setFormValue("services", services);
  };

  /* DateTime */

  const onTimeStartChange = (nextTimeStart) => {
    setFormValue("timeStart", nextTimeStart);
  };

  const onDateChange = (nextDate) => {
    setFormValue("date", wrapFormDate(nextDate));
  };

  const onCalendarStartDateChange = (nextCalendarStartDate) => {
    setFormValue(
      "calendarStartDate",
      wrapFormDate(getCalendarStartDate(nextCalendarStartDate)),
    );
  };

  const onDatePickerOpen = () => {
    if (form.date) {
      onCalendarStartDateChange(form.date);
    }
  };

  const onIsOutsideScheduleHoursChange = () => {
    const nextState = !form.isOutsideScheduledHours;
    if (
      !nextState &&
      !availableHoursInsideScheduleByDate.includes(form.timeStart)
    ) {
      setFormValue("timeStart", "");
    }
    setFormValue("isOutsideScheduledHours", nextState);
  };

  const onGoToPrevDate = () => {
    if (form.date) {
      onDateChange(moment.utc(form.date).add(-1, "days"));
    }
  };

  const onGoToNextDate = () => {
    if (form.date) {
      onDateChange(moment.utc(form.date).add(1, "days"));
    }
  };

  const timeEnd = (() =>
    makeDurationInTime(
      form.services.reduce((d, s) => (d += s.durationInMin), 0) ||
        TIME_END_DURATION_IN_MIN_FALLBACK,
      form.timeStart,
    ))();

  /* Clients */

  const handleClientNameChange = (nextClientName) => {
    setFormValue("clientName", nextClientName);
    setFormValue("clientId", "");
  };

  const handleClientPhoneCodeChange = (nextClientPhoneCode) => {
    setFormValue("clientPhoneCode", nextClientPhoneCode);
  };

  const handleSelectClient = (nextClient) => {
    setFormValue("clientId", nextClient.id);
    setFormValue("clientName", nextClient.fullName);
    setFormValue("clientEmail", nextClient.email);
    setFormValue("clientPhone", nextClient.phone);
    setFormValue("clearentZipCode", nextClient.pinCode);
    setFormValue("clearentEmail", nextClient.email);
    setFormValue("doNotEmail", nextClient.doNotEmail);
    setFormValue("doNotSms", nextClient.doNotSms);
    setCardSelected("");
  };

  const handleClientUpdate = ({ id, name, email, phone, phoneCode }) => {
    if (form.clientId !== id) {
      // Created New Client
      setFormValue("doNotEmail", false);
      setFormValue("doNotSms", false);
    }
    setFormValue("clientId", id);
    setFormValue("clientName", name);
    setFormValue("clientEmail", email);
    setFormValue("clientPhone", phone);
    setFormValue("clientPhoneCode", phoneCode);
    setFormValue("clearentEmail", email);
    setNewCard(true);
  };

  /* Additionally */

  const onEnterCreditCardChange = (nextState) => {
    setFormValue("isEnterCreditCardDetails", nextState);
    if (!nextState) {
      setFormValue("isAddNewCard", false);
    }
  };

  const onIsDoubleBookingChange = (nextState) => {
    setFormValue("isDoubleBooking", nextState);
  };

  /* Credit Cards */

  const onClearentZipCodeChange = (nextClearentZipCode) => {
    setFormValue("clearentZipCode", nextClearentZipCode);
  };

  const onClearentEmailChange = (nextClearentEmail) => {
    setFormValue("clearentEmail", nextClearentEmail);
  };

  const onToggleCCForm = () => {
    setFormValue("isAddNewCard", true);
  };

  const onClearentCardChange = (nextClearentCard) => {
    setFormValue("selectedClearentCard", nextClearentCard);
  };

  /* Notification */

  const onNotificationModalClose = () => {
    setNotification(null);
    if (currentUser.account.integrations?.isQualiphyEnabled) {
      setIsConfirmCreateExamModalOpen(true);
    } else {
      history.push(cancelRoute);
    }
  };

  const onNotificationModalSubmit = (values) => {
    if (notification) {
      createAppointmentNotification({
        notificationType: values.type,
        notificationBody: values.body,
        sms: notification.sms,
        subject: notification.subject,
        appointmentId: notification.appointmentId,
        notifyFrom: "create",
      }).then(onNotificationModalClose);
    }
  };

  /* Submission */

  const onSubmit = () => {
    if (isEditMode && isCancelationFeeIncluded) {
      setIsCancelationModalOpen(true);
    } else {
      submit(mutateAppointment);
    }
  };

  const submitWithCancelationFee = (chargeStatus) => {
    setFormValue("isPatientToBeCharged", chargeStatus);
    setIsCancelationModalOpen(false);
    submit(mutateAppointment, { isPatientToBeCharged: chargeStatus });
  };

  const submitWithConfirmedCancellationPolicyExclusion = () => {
    setFormValue("isCancellationPolicyExcludeConfirmed", true);
    setIsConfirmExcludingCancellationPolicyModalOpen(false);
    submit(mutateAppointment, { isCancellationPolicyExcludeConfirmed: true });
  };

  /* Side effects */

  useEffect(() => {
    // APT_PAY: See point I in usePaymentInfoAppointment
    setFormValue(
      "isEnterCreditCardDetails",
      isCanTakePaymentWithDeposit || isCanTakePaymentWithCancelationPolicy,
    );
  }, [isCanTakePaymentWithDeposit, isCanTakePaymentWithCancelationPolicy]);

  useEffect(() => {
    /**
     * Display confirm services minimum deposit modal
     * we are converting waitlist to appointment &
     * when clinic can take payment &
     * list of services includes any service that required minimum deposit confirmation
     */
    if (
      form.isConvertingWaitlist &&
      isClinicCanTakePayment &&
      !confirmServicesDepositModal.hasAlreadyConfirmedUserChoice &&
      form.services.some((serviceDetails) => serviceDetails.price > 0)
    ) {
      setConfirmServicesDepositModal({
        open: true,
        hasAlreadyConfirmedUserChoice: true,
      });
    }
  }, [
    form.isConvertingWaitlist,
    isClinicCanTakePayment,
    form.services,
    confirmServicesDepositModal.hasAlreadyConfirmedUserChoice,
  ]);

  useEffect(() => {
    if (
      !isPatientCardDetailsFetching &&
      patientCardDetails &&
      Boolean(patientCardDetails.pinCode)
    ) {
      setFormValue("clearentZipCode", patientCardDetails.pinCode);
    }
  }, [isPatientCardDetailsFetching]);

  useEffect(() => {
    if (
      form.clientId &&
      userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe &&
      form.type !== APPOINTMENT_TYPES.waitList &&
      form.clinicId
    ) {
      const formData = {
        patient_id: form.clientId,
        clinic_id: form.clinicId,
      };
      getSetupIntent(formData);
      getClientCardDataStripe({ patient_id: formData.patient_id });

      if (form.type && Boolean(cardList?.length)) {
        form.isAddNewCard = false;
      }
    }
  }, [form.clientId, params.clientId, form.type, form.clinicId]);

  const handleValidation = () => {
    let error = false;

    if (!form.providerId) {
      uiNotification.error("Please Select A Provider");
      error = true;
    }
    if (!form.clinicId) {
      uiNotification.error("Please select a Clinic");
      error = true;
    }
    if (form.services.length === 0) {
      uiNotification.error("Please select a Service");
      error = true;
    }
    if (!form.timeStart) {
      uiNotification.error("Please select an hour");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    setNewCard(form.isAddNewCard);
  }, [form.isAddNewCard]);

  useEffect(() => {
    if (cardList?.length === 1) {
      setCardSelected(cardList[0]?.id);
    }
    if (cardList?.length === 0) {
      setNewCard(true);
    } else {
      setNewCard(false);
    }
    if (cardsList?.length === 0) {
      handlePaymentMethodView("link");
    } else {
      handlePaymentMethodView("card");
    }
  }, [cardList]);

  const changeCardSelected = (e) => {
    e.preventDefault();
    setCardSelected(e.target.value);
  };

  const saveAppointmentStripe = () => {
    if (form.isEnterCreditCardDetails) {
      if (cardSelected) {
        onSubmit();
      } else {
        uiNotification.clear();
        if (!form.clientId)
          uiNotification.error("Select client from the search list");
        else uiNotification.error("Please select a card");
      }
    } else {
      onSubmit();
    }
  };

  const showStripeCommonButtons = () => {
    return (
      !form.isEnterCreditCardDetails ||
      Boolean(cardList?.length) ||
      !form.clientId
    );
  };

  const handlePaymentMethodView = (view) => {
    setCardSelected("");
    setSavedPaymentMethodView(view);
  };

  /* --------- */

  return (
    <div className={classes.appointmentContainer}>
      <div className={classes.types}>
        {!isEditMode && !leadId && (
          <EntityRow label="Type" className={classes.typeGutter} width="none">
            <CommonType
              eventType={eventType}
              onChange={(nextEventType) =>
                changeEventType({ eventType: nextEventType })
              }
            />
          </EntityRow>
        )}
        <EntityRow
          label="Appointment&nbsp;Type"
          labelClassName={classes.typeLabel}
          width="none"
        >
          <AppointmentType
            isConvertingWaitlist={form.isConvertingWaitlist}
            value={form.type}
            onChange={onTypeChange}
            isDisabled={isEditMode}
          />
        </EntityRow>
      </div>
      {form.type === APPOINTMENT_TYPES.waitList ? (
        <WaitList />
      ) : (
        <>
          <AppointmentProvider
            providerError={hasError("providerId")}
            providerId={form.providerId}
            onChange={onProviderIdChange}
            providers={providers}
            isDisabled={isRescheduleMode}
          />
          <CommonClinic
            clinicError={hasError("clinicId")}
            clinicId={form.clinicId}
            onChange={onClinicIdChange}
            clinics={clinics}
            isDisabled={isRescheduleMode}
          />
          {Boolean(form.clinicId) && (
            <AppointmentServices
              servicesError={hasError("services")}
              services={services}
              selectedServices={form.services}
              onSelect={onSelectService}
              onRemove={onRemoveService}
              onEdit={onEditService}
              isMultiService={
                !isRescheduleMode && form.type === APPOINTMENT_TYPES.inPerson
              }
              isSelectDisabled={isRescheduleMode}
              isDeletable={!isRescheduleMode}
              isClinicCanTakePayment={isClinicCanTakePayment}
            />
          )}
          <AppointmentDateTime
            date={form.date}
            includeDates={availableDays}
            onDatePickerChange={onDateChange}
            onDatePickerOpen={onDatePickerOpen}
            onDatePickerMonthChange={onCalendarStartDateChange}
            onBookingOutsideScheduleChange={onIsOutsideScheduleHoursChange}
            onTimeStartSelect={onTimeStartChange}
            onGoToPrevDate={onGoToPrevDate}
            onGoToNextDate={onGoToNextDate}
            isLoading={isLoading}
            isDatePickerError={hasError("date")}
            isDatePickerDisabled={form.services.length === 0}
            isTimePickerActive={Boolean(
              form.date && form.services.some((s) => s.durationInMin),
            )}
            isTimePickerError={hasError("timeStart")}
            isBookingOutsideSchedule={form.isOutsideScheduledHours}
            timeStart={form.timeStart}
            timeEnd={timeEnd}
            openToDate={form.date || form.calendarStartDate}
            timeStartList={availableHoursByDate}
            timeStartInsideScheduleList={availableHoursInsideScheduleByDate}
            isDoubleBookingAllowed={form.isDoubleBooking}
            onDoubleBookingAllowedChange={onIsDoubleBookingChange}
          />
          <ClientHandler
            readonly={(isEditMode && !isRescheduleMode) || Boolean(leadId)}
            id={form.clientId}
            name={form.clientName}
            email={form.clientEmail}
            phone={form.clientPhone}
            phoneCode={form.clientPhoneCode}
            isNameError={hasError("clientName")}
            isEmailError={hasError("clientEmail")}
            isPhoneError={
              hasError("clientPhone") || hasError("clientPhoneCode")
            }
            onSelect={handleSelectClient}
            onUpdate={handleClientUpdate}
            onNameChange={handleClientNameChange}
            onPhoneCodeChange={handleClientPhoneCodeChange}
            changeIsSubmitActive={changeIsSubmitActive}
            appointmentType={form.type}
          />
          <CommonNotes
            notes={form.notes}
            onNotesChange={(nextNotes) => setFormValue("notes", nextNotes)}
            isDisabled={isRescheduleMode}
          />
          {/* APT_PAY: See point I in usePaymentInfoAppointment */}
          {(isCanTakePaymentWithDeposit ||
            isCanTakePaymentWithCancelationPolicy) &&
            form.clientId && (
              <CommonAdditionally
                isEnterCreditCard={form.isEnterCreditCardDetails}
                onEnterCreditCardChange={onEnterCreditCardChange}
              />
            )}
          {form.isEnterCreditCardDetails && (
            <>
              {(form.isAddNewCard || !patientCardDetails.cardNumber) &&
                userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent && (
                  <CommonCCForm
                    ref={stripeCardNumberRef}
                    stripeInstance={stripe}
                    isClearentZipError={hasError("clearentZipCode")}
                    clearentZip={form.clearentZipCode}
                    onClearentZipChange={onClearentZipCodeChange}
                    isClearentEmailError={hasError("clearentEmail")}
                    clearentEmail={form.clearentEmail}
                    onClearentEmailChange={onClearentEmailChange}
                    clearentClinic={clearentClinic}
                  />
                )}
              {!form.isAddNewCard &&
                Boolean(patientCardDetails.cardNumber) &&
                userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent && (
                  <CommonCCDetails
                    cardNumber={patientCardDetails.cardNumber}
                    toggleCCForm={onToggleCCForm}
                    isClearentZipError={hasError("clearentZipCode")}
                    clearentZip={form.clearentZipCode}
                    onClearentZipChange={onClearentZipCodeChange}
                    isClearentEmailError={hasError("clearentEmail")}
                    clearentEmail={form.clearentEmail}
                    onClearentEmailChange={onClearentEmailChange}
                    clearentCardList={patientCCOptions}
                    onClearentCardChange={onClearentCardChange}
                  />
                )}

              {!form.isAddNewCard &&
                form.clientId &&
                Boolean(cardList?.length) &&
                userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe && (
                  <>
                    <div className="m-t-80">
                      <span className="width130 col-xs-2 no-padding font-size-16 color-gray0">
                        Payment Method
                      </span>
                      <span className="col-xs-5 mr-30 m-b-30">
                        {haveLinkAndCardSaved && (
                          <Tabs
                            tabClassName={clsx("m-b-15", "min-w-100")}
                            variant="tiled"
                            active={savedPaymentMethodView}
                            options={[
                              {
                                label: "Saved Cards",
                                value: "card",
                                onClick: () => handlePaymentMethodView("card"),
                              },
                              {
                                label: "Link",
                                value: "link",
                                onClick: () => {
                                  handlePaymentMethodView("link");
                                },
                              },
                            ]}
                          />
                        )}
                        <div className="new-field-label">
                          {savedPaymentMethodView === "card"
                            ? "Credit Card(s)"
                            : "Link"}
                        </div>
                        <select
                          className="setting-select-box"
                          value={cardSelected}
                          onChange={changeCardSelected}
                        >
                          {!cardSelected && (
                            <option value="">
                              Select{" "}
                              {savedPaymentMethodView === "card"
                                ? "card"
                                : "link"}
                            </option>
                          )}
                          <CommonCardList
                            savedPaymentMethodView={savedPaymentMethodView}
                            cardsList={cardsList}
                            linkList={linkList}
                          />
                        </select>
                      </span>
                    </div>

                    <CommonCCDetails
                      cardNumber={patientCardDetails.cardNumber}
                      toggleCCForm={onToggleCCForm}
                      isClearentZipError={hasError("clearentZipCode")}
                      clearentZip={form.clearentZipCode}
                      onClearentZipChange={onClearentZipCodeChange}
                      isClearentEmailError={hasError("clearentEmail")}
                      clearentEmail={form.clearentEmail}
                      onClearentEmailChange={onClearentEmailChange}
                      cardOnFileStripe={true}
                      clearentCardList={patientCCOptions}
                      onClearentCardChange={onClearentCardChange}
                    />
                  </>
                )}

              {userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe &&
                form.clientId &&
                (form.isAddNewCard || Boolean(!cardList?.length)) && (
                  <EntityRow label="Payment Method">
                    <StripePaymentForm
                      type="setupIntent"
                      onSubmit={onSubmit}
                      onCancel={() => history.push(cancelRoute)}
                      validation={() => handleValidation()}
                      key={form.clientId}
                      onSubmitText={"Save"}
                    />
                  </EntityRow>
                )}
            </>
          )}
          {/* APT_PAY: See point IV.1 in usePaymentInfoAppointment */}
          {isCancellationPolicyTipVisible && (
            <AlertBox className={classes.alert}>
              {`In order to use the cancellation policy, please turn point of sale on. Go to: Settings > Pos > Pos Settings and toggle this feature ON then complete the application. `}
            </AlertBox>
          )}

          {userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent && (
            <CommonControlButtons
              onCancel={() => history.push(cancelRoute)}
              onSubmit={onSubmit}
              isSubmitEnabled={isEventSubmitActive}
            />
          )}
          {userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe &&
            showStripeCommonButtons() &&
            !form.isAddNewCard && (
              <CommonControlButtons
                onCancel={() => history.push(cancelRoute)}
                onSubmit={() => saveAppointmentStripe()}
                isSubmitEnabled={isEventSubmitActive}
              />
            )}

          {notification && (
            <AppointmentNotificationModal
              notification={notification}
              onClose={onNotificationModalClose}
              onSubmit={onNotificationModalSubmit}
            />
          )}
          {isCancelationFeeIncluded && (
            <AppointmentReschedulingModal
              isOpen={isCancelationFeeModalOpen}
              onClose={() => setIsCancelationModalOpen(false)}
              onCharge={() => submitWithCancelationFee(true)}
              onCancel={() => submitWithCancelationFee(false)}
              cancellationFee={appointmentFees.cancelationFee}
            />
          )}
          {isConfirmExcludingCancellationPolicyModalOpen && (
            <ConfirmModal
              isOpen={isConfirmExcludingCancellationPolicyModalOpen}
              onClose={() =>
                setIsConfirmExcludingCancellationPolicyModalOpen(false)
              }
              onCancel={() =>
                setIsConfirmExcludingCancellationPolicyModalOpen(false)
              }
              onConfirm={submitWithConfirmedCancellationPolicyExclusion}
            >
              You are not enrolling this appointment in the cancellation policy
            </ConfirmModal>
          )}
          {confirmServicesDepositModal.open && (
            <ConfirmServicesDepositModal
              services={form.services}
              handleClose={handleCloseServiceDepositModal}
              handleUpdateServices={updateServices}
            />
          )}
          <ModalConfirmCreateExam
            isOpen={isConfirmCreateExamModalOpen}
            onClose={() => {
              setIsConfirmCreateExamModalOpen(false);
              history.push(cancelRoute);
            }}
            onSend={() => {
              setIsConfirmCreateExamModalOpen(false);
              setIsCreateExamModalOpen(true);
            }}
          />
          {isCreateExamModalOpen && createdAppointmentId && (
            <ModalCreateQualiphyProcedure
              isOpen
              patientId={form.clientId}
              appointmentId={createdAppointmentId}
              onClose={() => {
                setIsCreateExamModalOpen(false);
                history.push(cancelRoute);
              }}
              onSuccess={() => history.push(cancelRoute)}
              initialValues={{
                clinicId: form.clinicId,
              }}
            />
          )}
          {isLoading && <Loader />}
        </>
      )}
    </div>
  );
};

Appointment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  history: PropTypes.object,
  editEventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editEventData: PropTypes.object,
  eventType: PropTypes.string,
  isEventSubmitActive: PropTypes.bool,
  changeEventType: PropTypes.func,
  changeIsSubmitActive: PropTypes.func,
  stripeIntent: PropTypes.object,
  getSetupIntent: PropTypes.func,
  cardList: PropTypes.array,
  getClientCardDataStripe: PropTypes.func,
};

const mapStateToProps = (state) => ({
  eventType: event.selectors.selectEventType(state),
  isEventSubmitActive: event.selectors.selectIsSubmitActive(state),
  stripeIntent: state.StripeReducer.stripeIntent,
  cardList: state.StripeReducer.cardList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeEventType: event.actions.eventTypeChange,
      changeIsSubmitActive: event.actions.isSubmitActiveChange,
      getSetupIntent,
      getClientCardDataStripe,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Appointment));
